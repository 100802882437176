import { createContext, useContext, useEffect, useState } from "react";
import api from "@/config/axios.js";

const TaskContext = createContext();

export const TaskProvider = ({ children }) => {
  const [labels, setLabels] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [taskDetails, setTaskDetails] = useState(null);
  const [isTaskDrawerOpen, setIsTaskDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedTask) {
      setLoading(true);

      api
        .get(`/task/tasks/${selectedTask}`)
        .then((res) => {
          setTaskDetails(res);
          setIsTaskDrawerOpen(true);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }
  }, [selectedTask]);

  useEffect(() => {
    setLoading(true);
    Promise.all([api.get("/task/task-labels"), api.get("/task/tasks")])
      .then(([labelsRes, tasksRes]) => {
        setLabels(labelsRes);
        setTasks(tasksRes);
      })
      .catch((err) => {
        console.error("Error fetching initial data:", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const deleteTaskById = (taskId) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      api
        .delete(`/task/tasks/${taskId}`)
        .then((res) => {
          // setTasks((prevTasks) =>
          //   prevTasks.filter((task) => task.id !== taskId),
          // );
          api
            .get("/task/tasks")
            .then((data) => {
              setTasks(data);
              resolve(res);
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
              reject(err);
            });
        })
        .catch((error) => {
          console.log(error);
          reject(error);
          setLoading(false);
        });
    });
  };

  return (
    <TaskContext.Provider
      value={{
        labels,
        setLabels,
        tasks,
        setTasks,
        selectedTask,
        setSelectedTask,
        isTaskDrawerOpen,
        setIsTaskDrawerOpen,
        taskDetails,
        loading,
        deleteTaskById,
      }}
    >
      {children}
    </TaskContext.Provider>
  );
};

export const useTask = () => {
  const context = useContext(TaskContext);
  if (context === undefined) {
    throw new Error("useTask must be used within a TaskProvider");
  }
  return context;
};
