import React, { createContext, useContext, useEffect, useState } from 'react';

const IntegrationContext = createContext({
  integrations: [],
})

export const useIntegration = () => {
  return useContext(IntegrationContext);
}

export const IntegrationProvider = ({ children }) => {
  const [integrations, setIntegrations] = useState([]);
  const [callback, setCallback] = useState(null);

  return (
    <IntegrationContext.Provider value={{
      integrations,
      callback,
      setCallback
    }}>
      {children}
    </IntegrationContext.Provider>
  )
}
