import axios from 'axios';
import {DeviceUtils} from "@utils/device-utils.js";
import {SessionUtils} from "@utils/sassion-management.js";
import {googleLogout} from "@react-oauth/google";
import {setAPIUrl} from "@/utils/common.js";

// Create axios instance with default config

const api = axios.create({
  baseURL: `${setAPIUrl()}/api/v1`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: false,
  timeout: 30000,
});

// Request interceptor for adding auth token
api.interceptors.request.use(
  (config) => {
    const session = SessionUtils.getSession();
    if (session?.token) {
      config.headers.Authorization = `Bearer ${session?.token}`;
    }
    const deviceInfo = DeviceUtils.getDeviceInfo();
    config.headers['Device-Info'] = JSON.stringify(deviceInfo);
    config.headers.Timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return config;
  },
  (error) => {
    return Promise.reject(error.response?.data || error);
  }
);

// Response interceptor for handling token refresh, errors, and response normalization
// Flag to track if we're currently refreshing
let isRefreshing = false;
// Queue of requests to retry after token refresh
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

api.interceptors.response.use(
  (response) => {
    return response.data?.data || response.data || response;
  },
  async (error) => {
    const originalRequest = error.config;
    const session = SessionUtils.getSession();

    if (error.response?.status === 401 && !originalRequest._retry) {
      if (originalRequest.url.includes('/auth/refresh-token')) {
        // If refresh token request fails, clear session and redirect
        SessionUtils.clearSession();
        window.location.href = '/login';
        throw error;
      }

      originalRequest._retry = true;

      if (!session?.refreshToken) {
        throw error.response?.data || error;
      }

      // If refresh already in progress, queue this request
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then(token => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return api(originalRequest);
          })
          .catch(err => {
            throw err;
          });
      }

      isRefreshing = true;

      try {
        const response = await api.post('/auth/refresh-token', {
          refreshToken: session.refreshToken
        });

        const newToken = response.token;

        // Update session
        SessionUtils.setSession(
          newToken,
          response.refreshToken,
          session.isRemembered
        );

        // Update request header
        originalRequest.headers.Authorization = `Bearer ${newToken}`;

        // Process any queued requests
        processQueue(null, newToken);

        isRefreshing = false;

        return api(originalRequest);
      } catch (refreshError) {
        processQueue(refreshError, null);
        isRefreshing = false;
        SessionUtils.clearSession();
        window.location.href = '/login';
        throw refreshError;
      }
    }

    throw error.response?.data || error;
  }
);

export const tokenManager = {
  refreshTimer: null,

  startAutoRefresh() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }

    this.refreshTimer = setInterval(async () => {
      try {
        const session = SessionUtils.getSession();
        if (!session) return;

        const response = await api.post('/auth/refresh-token', {
          refreshToken: session.refreshToken
        });

        SessionUtils.setSession(
          response.token,
          response.refreshToken,
          session.isRemembered
        );
      } catch (error) {
        console.error('Token refresh failed:', error);
        SessionUtils.clearSession();
        window.location.href = '/login';
      }
    }, 7 * 60 * 60 * 1000);
  },

  stopAutoRefresh() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }
};

// Auth related API calls
export const authAPI = {
  login: async (email, password, rememberMe) => {
    const data = await api.post('/auth/login', { email, password });
    SessionUtils.setSession(data?.tokens?.accessToken, data?.tokens?.refreshToken, rememberMe);
    tokenManager.startAutoRefresh();
    return data;
  },

  google: async (userData) => {
    const data = await api.post('/auth/google', userData);
    SessionUtils.setSession(data?.tokens?.accessToken, data?.tokens?.refreshToken, true);
    tokenManager.startAutoRefresh();
    return data;
  },

  register: async (userData) => {
    const data = await api.post('/auth/register', userData);
    SessionUtils.setSession(data?.tokens?.accessToken, data?.tokens?.refreshToken, true);
    tokenManager.startAutoRefresh();
    return data;
  },

  verifyToken: async () => {
    return api.get('/auth/verify');
  },

  logout: async () => {
    const data = await api.post('/auth/logout');
    tokenManager.stopAutoRefresh();
    SessionUtils.clearSession();
    return data;
  },

  clearGoogleSession: () => {
    localStorage.removeItem('taskweave_user');
    googleLogout();
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
  },

  checkAccount: async (email) => {
    return api.get(`/auth/check-account?email=${email}`);
  }
};

export default api;
