import React, { useState, useEffect } from 'react';
import { useToast } from "@/hooks/use-toast";
import AuthLayout from "@/components/common/layout/AuthLayout";
import {SettingsHeader} from "@/pages/workspace/components/workspace-settings/SettingsHeader.jsx";
import {SettingsSidebar} from "@/pages/workspace/components/workspace-settings/SettingsSidebar.jsx";
import {
  WorkspaceDetailsSection
} from "@/pages/workspace/components/workspace-settings/sections/WorkspaceDetailsSection.jsx";
import {navigationGroups} from "@/pages/workspace/components/workspace-settings/data/navigationGroups.js";
import {
  AutoCategorizationSection
} from "@/pages/workspace/components/workspace-settings/sections/task-extraction/AutoCategorizationSection.jsx";
import {
  TaskPropertiesSection
} from "@/pages/workspace/components/workspace-settings/sections/task-extraction/TaskPropertiesSection.jsx";
import {
  DetectionSettingsSection
} from "@/pages/workspace/components/workspace-settings/sections/task-extraction/DetectionSettingsSection.jsx";
import {
  SchedulePreferencesSection
} from "@/pages/workspace/components/workspace-settings/sections/meeting/SchedulePreferencesSection.jsx";
import {
  ActionItemsSection
} from "@/pages/workspace/components/workspace-settings/sections/meeting/ActionItemsSection.jsx";
import {
  MeetingDefaultsSection
} from "@/pages/workspace/components/workspace-settings/sections/meeting/MeetingDefaultsSection.jsx";
import {
  ImportExportSection
} from "@/pages/workspace/components/workspace-settings/sections/advanced/ImportExportSection.jsx";
import {Check, Loader2} from "lucide-react";
import {useProfileStore} from "@/store/slices/profileSlice.js";
import {getCurrentWorkspace} from "@/utils/common.js";
import api from "@/config/axios.js";
import {Card, CardContent, CardDescription, CardHeader, CardTitle} from "@/components/ui/card.jsx";
import {Button} from "@/components/ui/button.jsx";
const initialSettings = {
  general: {
    workspaceName: "My Workspace",
    description: "",
    theme: "light",
    language: "en",
    timeZone: "UTC",
    dateFormat: "MM/DD/YYYY",
    firstDayOfWeek: "sunday",
    privacy: "private",
    autoSave: true
  },
  detection: {
    sensitivity: 'medium',
    sources: {
      email: true,
      chat: true,
      documents: true,
      meetings: true
    },
    keywordEnabled: true,
    nlpEnabled: true
  },
  taskProperties: {
    defaultPriority: 'medium',
    defaultStatus: 'todo',
    defaultAssignee: 'creator',
    dueDateBehavior: 'none'
  },
  categorization: {
    smartLabels: true,
    projectDetection: true,
    tagRules: false,
    tagRulesBehavior: 'suggest',
    suggestions: true
  },
  schedule: {
    workingHoursStart: '9',
    workingHoursEnd: '17',
    bufferTime: '15',
    preferredDays: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true
    },
    focusTimeEnabled: false
  },
  actionItems: {
    autoDetection: true,
    taskCreation: 'prompt',
    reminderFrequency: 'daily'
  },
  meetingDefaults: {
    duration: '30',
    autoRecord: true,
    transcription: true,
    notesTemplate: 'basic',
    participantControls: {
      screenShare: true,
      chat: true
    }
  }
};
const WorkspaceSettings = () => {
  const { toast } = useToast();
  const {workspaces} = useProfileStore()
  const [activeItem, setActiveItem] = useState('workspace-details');
  const [searchQuery, setSearchQuery] = useState("");
  const [settingsChanged, setSettingsChanged] = useState(false);
  const [settings, setSettings] = useState(initialSettings);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [debouncedSettings, setDebouncedSettings] = useState({});
  const [pendingTextChanges, setPendingTextChanges] = useState({});
  const workspaceId = getCurrentWorkspace(workspaces)?.id;
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const id = params.get('id');
    if (id && navigationGroups.some(group => group.items.some(item => item.id === id))) {
      setActiveItem(id);
    }
  }, []);

  useEffect(() => {
    fetchWorkspaceSettings();
  }, [workspaceId]);

  const fetchWorkspaceSettings = async () => {
    try {
      setIsLoading(true);
      setError(null);

      const data = await api.get(`/workspace/${workspaceId}/settings`);
      if (!data) {
        throw new Error('Failed to fetch workspace settings');
      }

      setSettings({
        ...initialSettings,
        ...data
      });
    } catch (error) {
      setError(error.message);
      toast({
        title: "Warning",
        description: "Using default settings. Changes may not be saved.",
        variant: "warning",
      });
      // Keep using initialSettings on error
      setSettings(initialSettings);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (settingsChanged) {
      // Only save non-text changes immediately
      const hasNonTextChanges = Object.keys(debouncedSettings).length > 0;
      if (hasNonTextChanges) {
        saveSettings(debouncedSettings);
      }
    }
  }, [debouncedSettings]);

  // Handle text field blur - save pending changes
  const handleTextFieldBlur = (section, key) => {
    if (pendingTextChanges[section]?.[key]) {
      const updatedSettings = {
        ...debouncedSettings,
        [section]: {
          ...(debouncedSettings[section] || {}),
          [key]: pendingTextChanges[section][key]
        }
      };
      setDebouncedSettings(updatedSettings);

      // Clear the pending change
      setPendingTextChanges(prev => ({
        ...prev,
        [section]: {
          ...(prev[section] || {}),
          [key]: undefined
        }
      }));
    }
  };

  const saveSettings = async (changedSettings) => {
    try {
      setIsLoading(true);
      const data = await api.patch(`/workspace/${workspaceId}/settings`, JSON.stringify({
        settings: changedSettings,
        updatedFields: Object.keys(changedSettings).map(section =>
          Object.keys(changedSettings[section]).map(key => `${section}.${key}`)
        ).flat()
      }));

      setIsLoading(false);

      // Merge saved data with initialSettings
      setSettings({
        ...initialSettings,
        ...data
      });
      toast({
        title: "Settings saved",
        description: "Your changes have been saved successfully.",
      });
      setSettingsChanged(false);
      setDebouncedSettings({});

    } catch (error) {
      toast({
        title: "Error saving settings",
        description: error.message || "Please try again later.",
        variant: "destructive",
      });
      setIsLoading(false);
    }
  };

  const handleSettingChange = (section, key, value, isTextField = false) => {
    const keyParts = key.split('.');

    // Function to create nested updates
    const createNestedUpdate = (obj, parts, finalValue) => {
      if (parts.length === 1) {
        return {
          ...obj,
          [parts[0]]: finalValue
        };
      }

      const [current, ...remaining] = parts;
      return {
        ...obj,
        [current]: createNestedUpdate(
          obj[current] || {},
          remaining,
          finalValue
        )
      };
    };

    if (isTextField) {
      // For text fields, store in pending changes
      setPendingTextChanges(prev => ({
        ...prev,
        [section]: keyParts.length === 1
          ? {
            ...(prev[section] || {}),
            [key]: value
          }
          : createNestedUpdate(
            prev[section] || {},
            keyParts,
            value
          )
      }));

      // Update local state for UI
      setSettings(prev => ({
        ...prev,
        [section]: keyParts.length === 1
          ? {
            ...prev[section],
            [key]: value
          }
          : createNestedUpdate(
            prev[section],
            keyParts,
            value
          )
      }));
    } else {
      // For non-text fields, update immediately
      setSettings(prev => ({
        ...prev,
        [section]: keyParts.length === 1
          ? {
            ...prev[section],
            [key]: value
          }
          : createNestedUpdate(
            prev[section],
            keyParts,
            value
          )
      }));

      setDebouncedSettings(prev => ({
        ...prev,
        [section]: keyParts.length === 1
          ? {
            ...(prev[section] || {}),
            [key]: value
          }
          : createNestedUpdate(
            prev[section] || {},
            keyParts,
            value
          )
      }));
    }

    setSettingsChanged(true);
  };

  if (isLoading && !settings) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }



  return (
    <div className="min-h-screen">
      <AuthLayout
        childrenHeader={
          <SettingsHeader
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        }
      >
        <div className="flex h-screen bg-background">
          <SettingsSidebar
            navigationGroups={navigationGroups}
            activeItem={activeItem}
            setActiveItem={setActiveItem}
          />
          <div className={`flex-1`}>
            <div >
              {
                error && (
                  <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                    <strong className="font-bold">Error: </strong>
                    <span className="block sm:inline ml-2">{error}</span>
                  </div>
                )
              }
                <CardHeader >
                  <div className={`flex justify-between items-center`}>
                    <div>
                      <CardTitle className="text-2xl font-semibold">Workspace Details</CardTitle>
                      <CardDescription className="text-base">
                        Manage your workspace information and preferences
                      </CardDescription>
                    </div>
                    <div>
                      <Button variant="default" onClick={saveSettings} disabled={!isLoading}>
                        {isLoading ? (
                          <>Saving...</>
                        ) : (
                          <>
                            <Check className="mr-2 h-4 w-4"/> Saved
                          </>
                        )}
                      </Button>
                    </div>
                  </div>
                </CardHeader>
                  {activeItem === 'workspace-details' && (
                    <WorkspaceDetailsSection
                      settings={settings}
                      handleSettingChange={handleSettingChange}
                      handleTextFieldBlur={handleTextFieldBlur}
                    />
                  )}
                  {activeItem === 'detection' && (
                    <DetectionSettingsSection
                      settings={settings}
                      handleSettingChange={handleSettingChange}
                    />
                  )}
                  {activeItem === 'task-properties' && (
                    <TaskPropertiesSection
                      settings={settings}
                      handleSettingChange={handleSettingChange}
                    />
                  )}
                  {activeItem === 'categorization' && (
                    <AutoCategorizationSection
                      settings={settings}
                      handleSettingChange={handleSettingChange}
                    />
                  )}
                  {activeItem === 'schedule' && (
                    <SchedulePreferencesSection
                      settings={settings}
                      handleSettingChange={handleSettingChange}
                    />
                  )}
                  {activeItem === 'action-items' && (
                    <ActionItemsSection
                      settings={settings}
                      handleSettingChange={handleSettingChange}
                    />
                  )}
                  {activeItem === 'meeting-defaults' && (
                    <MeetingDefaultsSection
                      settings={settings}
                      handleSettingChange={handleSettingChange}
                    />
                  )}
                  {activeItem === 'import-export' && (
                    <ImportExportSection
                      settings={settings}
                      handleSettingChange={handleSettingChange}
                      toast={toast}
                    />
                  )}
            </div>
          </div>
        </div>
      </AuthLayout>
    </div>
  );
};

export default WorkspaceSettings;
