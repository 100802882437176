import { Plus, MoreHorizontal, MessageSquare, Paperclip } from 'lucide-react';

const TaskBoardView = () => {
    const statuses = [
        { id: 'todo', title: 'To Do', color: 'gray', count: 8 },
        { id: 'inProgress', title: 'In Progress', color: 'blue', count: 5 },
        { id: 'inReview', title: 'In Review', color: 'yellow', count: 3 },
        { id: 'done', title: 'Done', color: 'green', count: 12 }
    ];

    return (
        <div className="min-h-screen bg-gray-50">
            {/* Board Container */}
            <div className="p-6 h-[calc(100vh-120px)] overflow-x-auto">
                <div className="flex space-x-6 h-full">
                    {/* Status Columns */}
                    {statuses.map((status) => (
                        <div key={status.id} className="flex-shrink-0 w-80">
                            {/* Column Header */}
                            <div className="flex items-center justify-between mb-3">
                                <div className="flex items-center space-x-2">
                                    <h3 className="font-medium text-gray-900">{status.title}</h3>
                                    <span className="text-sm text-gray-500">({status.count})</span>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <button className="p-1 hover:bg-gray-100 rounded">
                                        <Plus className="h-4 w-4 text-gray-500" />
                                    </button>
                                    <button className="p-1 hover:bg-gray-100 rounded">
                                        <MoreHorizontal className="h-4 w-4 text-gray-500" />
                                    </button>
                                </div>
                            </div>

                            {/* Task Cards Container */}
                            <div className="bg-gray-100 rounded-lg p-4 h-full space-y-4">
                                {[1, 2, 3].map((task) => (
                                    <div
                                        key={task}
                                        className="bg-white p-4 rounded-lg shadow-sm hover:shadow cursor-pointer"
                                    >
                                        {/* Task Priority Tag */}
                                        <div className="flex items-center justify-between mb-3">
                      <span className="px-2 py-1 bg-yellow-50 text-yellow-700 text-xs rounded-full">
                        Medium Priority
                      </span>
                                            <button className="text-gray-400 hover:text-gray-600">
                                                <MoreHorizontal className="h-4 w-4" />
                                            </button>
                                        </div>

                                        {/* Task Title */}
                                        <h4 className="font-medium text-gray-900 mb-2">
                                            Update product roadmap documentation
                                        </h4>
                                        <p className="text-sm text-gray-500 mb-4">
                                            Review and update the Q4 product roadmap with new features and timeline.
                                        </p>

                                        {/* Task Metadata */}
                                        <div className="flex items-center justify-between text-sm text-gray-500">
                                            <div className="flex items-center space-x-4">
                                                <div className="flex items-center">
                                                    <MessageSquare className="h-4 w-4 mr-1" />
                                                    <span>4</span>
                                                </div>
                                                <div className="flex items-center">
                                                    <Paperclip className="h-4 w-4 mr-1" />
                                                    <span>2</span>
                                                </div>
                                            </div>

                                            <div className="flex items-center space-x-2">
                                                <div className="flex -space-x-2">
                                                    <div className="h-6 w-6 rounded-full bg-blue-500 border-2 border-white"></div>
                                                    <div className="h-6 w-6 rounded-full bg-green-500 border-2 border-white"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                {/* Add Task Button */}
                                <button className="w-full py-2 border-2 border-dashed border-gray-300 rounded-lg text-gray-500 hover:border-gray-400 hover:text-gray-600 transition-colors">
                                    <Plus className="h-5 w-5 mx-auto" />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TaskBoardView;
