export const Card = ({
                                              children,
                                              className = '',
                                              header,
                                              footer,
                                              noPadding = false,
                                          }) => {
    return (
        <div className={`bg-white rounded-xl shadow-sm ${className}`}>
            {header && (
                <div className="px-6 py-4 border-b border-gray-200">
                    {header}
                </div>
            )}

            <div className={noPadding ? '' : 'p-6'}>
                {children}
            </div>

            {footer && (
                <div className="px-6 py-4 border-t border-gray-200">
                    {footer}
                </div>
            )}
        </div>
    );
};

export const CardTitle = ({ children }) => (
    <h3 className="text-lg font-semibold text-gray-900">{children}</h3>
);

export const CardDescription = ({ children }) => (
    <p className="text-sm text-gray-500 mt-1">{children}</p>
);
