import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import {
  ChevronLeft, Calendar, Clock, CheckCircle2, XCircle,
  AlertCircle, Filter, Download, RefreshCcw, Search,
  ArrowDownWideNarrow, BarChart3, CheckCircle
} from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import AuthLayout from '@/components/common/layout/AuthLayout';
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

// Sample data for executions
const EXECUTION_LOGS = [
  {
    id: 1,
    timestamp: "2024-03-10T14:30:00",
    status: "success",
    trigger: "New Task Created",
    duration: "2.3s",
    actions_completed: 3,
    error: null,
    details: {
      trigger_data: {
        task_name: "Update documentation",
        priority: "High",
        assigned_to: "Alex Kim"
      },
      actions: [
        { name: "Create follow-up task", status: "success", duration: "0.8s" },
        { name: "Send Slack notification", status: "success", duration: "0.9s" },
        { name: "Update CRM", status: "success", duration: "0.6s" }
      ]
    }
  },
  {
    id: 2,
    timestamp: "2024-03-10T13:15:00",
    status: "failure",
    trigger: "New Task Created",
    duration: "1.8s",
    actions_completed: 1,
    error: "Slack API Error: Channel not found",
    details: {
      trigger_data: {
        task_name: "Review PR",
        priority: "Medium",
        assigned_to: "Sarah Chen"
      },
      actions: [
        { name: "Create follow-up task", status: "success", duration: "0.7s" },
        { name: "Send Slack notification", status: "failed", duration: "1.1s", error: "Channel not found" },
        { name: "Update CRM", status: "skipped" }
      ]
    }
  }
];

// Sample analytics data
const ANALYTICS_DATA = [
  { date: '03/04', executions: 45, successful: 43, failed: 2 },
  { date: '03/05', executions: 52, successful: 50, failed: 2 },
  { date: '03/06', executions: 48, successful: 47, failed: 1 },
  { date: '03/07', executions: 61, successful: 58, failed: 3 },
  { date: '03/08', executions: 55, successful: 54, failed: 1 },
  { date: '03/09', executions: 67, successful: 65, failed: 2 },
  { date: '03/10', executions: 60, successful: 58, failed: 2 }
];

const AutomationHistory = () => {
  const { id } = useParams();
  const [dateRange, setDateRange] = useState('7d');
  const [statusFilter, setStatusFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');

  const totalExecutions = EXECUTION_LOGS.length;
  const successfulExecutions = EXECUTION_LOGS.filter(log => log.status === 'success').length;
  const failureRate = ((totalExecutions - successfulExecutions) / totalExecutions * 100).toFixed(1);

  return (
    <div className="min-h-screen bg-zinc-50">
      <AuthLayout childrenHeader={
        <div className="w-full">
          <div className="flex items-center justify-between max-w-[1400px] mx-auto">
            <div className="flex items-center gap-3">
              <Link to="/automation" className="p-2 hover:bg-gray-100 rounded-lg transition-colors">
                <ChevronLeft className="h-4 w-4"/>
              </Link>
              <h1 className="text-xl font-bold">Task Auto-Assignment History</h1>
            </div>
            <div className="flex items-center gap-2">
              <Button variant="outline" size="sm">
                <Download className="h-4 w-4 mr-2"/>
                Export
              </Button>
              <Button variant="outline" size="sm">
                <RefreshCcw className="h-4 w-4 mr-2"/>
                Refresh
              </Button>
            </div>
          </div>
        </div>
      }>
        <div className="p-6 max-w-[1400px] mx-auto space-y-6">
          {/* Analytics Cards */}
          <div className="grid grid-cols-4 gap-4">
            <Card>
              <CardContent className="pt-6">
                <div className="text-sm text-muted-foreground mb-2">Total Executions</div>
                <div className="text-2xl font-bold">{totalExecutions}</div>
                <div className="text-sm text-muted-foreground mt-2">Last 7 days</div>
              </CardContent>
            </Card>
            <Card>
              <CardContent className="pt-6">
                <div className="text-sm text-muted-foreground mb-2">Success Rate</div>
                <div className="text-2xl font-bold text-green-600">{100 - Number(failureRate)}%</div>
                <div className="text-sm text-muted-foreground mt-2">+2.5% from last week</div>
              </CardContent>
            </Card>
            <Card>
              <CardContent className="pt-6">
                <div className="text-sm text-muted-foreground mb-2">Avg. Duration</div>
                <div className="text-2xl font-bold">2.1s</div>
                <div className="text-sm text-muted-foreground mt-2">-0.3s from last week</div>
              </CardContent>
            </Card>
            <Card>
              <CardContent className="pt-6">
                <div className="text-sm text-muted-foreground mb-2">Failed Executions</div>
                <div className="text-2xl font-bold text-red-600">{failureRate}%</div>
                <div className="text-sm text-muted-foreground mt-2">Last 7 days</div>
              </CardContent>
            </Card>
          </div>

          {/* Execution Trend Chart */}
          <Card>
            <CardHeader>
              <div className="flex items-center justify-between">
                <CardTitle>Execution Trend</CardTitle>
                <Select defaultValue={dateRange} onValueChange={setDateRange}>
                  <SelectTrigger className="w-32">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="24h">Last 24 hours</SelectItem>
                    <SelectItem value="7d">Last 7 days</SelectItem>
                    <SelectItem value="30d">Last 30 days</SelectItem>
                    <SelectItem value="90d">Last 90 days</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </CardHeader>
            <CardContent>
              <div className="h-80">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={ANALYTICS_DATA}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Line type="monotone" dataKey="successful" stroke="#22c55e" strokeWidth={2} />
                    <Line type="monotone" dataKey="failed" stroke="#ef4444" strokeWidth={2} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </CardContent>
          </Card>

          {/* Execution Logs */}
          <Card>
            <CardHeader>
              <div className="flex items-center justify-between">
                <CardTitle>Execution Logs</CardTitle>
                <div className="flex items-center gap-2">
                  <div className="relative">
                    <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                    <Input
                      placeholder="Search logs..."
                      className="pl-8 w-64"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                  <Select defaultValue={statusFilter} onValueChange={setStatusFilter}>
                    <SelectTrigger className="w-32">
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="all">All Status</SelectItem>
                      <SelectItem value="success">Successful</SelectItem>
                      <SelectItem value="failed">Failed</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                {EXECUTION_LOGS.map((log) => (
                  <div key={log.id} className="border rounded-lg p-4">
                    <div className="flex items-center justify-between mb-4">
                      <div className="flex items-center gap-3">
                        {log.status === 'success' ? (
                          <div className="p-2 bg-green-100 rounded-lg">
                            <CheckCircle2 className="h-4 w-4 text-green-600"/>
                          </div>
                        ) : (
                          <div className="p-2 bg-red-100 rounded-lg">
                            <XCircle className="h-4 w-4 text-red-600"/>
                          </div>
                        )}
                        <div>
                          <div className="font-medium">{log.trigger}</div>
                          <div className="text-sm text-muted-foreground">
                            {new Date(log.timestamp).toLocaleString()}
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center gap-4 text-sm text-muted-foreground">
                        <div>Duration: {log.duration}</div>
                        <div>Actions: {log.actions_completed}/{log.details.actions.length}</div>
                        <Button variant="ghost" size="sm">
                          View Details
                        </Button>
                      </div>
                    </div>

                    {log.error && (
                      <div className="bg-red-50 border border-red-100 rounded-md p-3 text-sm text-red-600 mt-2">
                        {log.error}
                      </div>
                    )}

                    <div className="mt-3 space-y-2">
                      {log.details.actions.map((action, index) => (
                        <div key={index} className="flex items-center text-sm">
                          {action.status === 'success' && (
                            <CheckCircle className="h-4 w-4 text-green-600 mr-2"/>
                          )}
                          {action.status === 'failed' && (
                            <XCircle className="h-4 w-4 text-red-600 mr-2"/>
                          )}
                          {action.status === 'skipped' && (
                            <Clock className="h-4 w-4 text-gray-400 mr-2"/>
                          )}
                          <span className="mr-2">{action.name}</span>
                          <span className="text-muted-foreground">
                                                        {action.duration && `(${action.duration})`}
                                                    </span>
                          {action.error && (
                            <span className="text-red-600 ml-2">- {action.error}</span>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        </div>
      </AuthLayout>
    </div>
  );
};

export default AutomationHistory;
