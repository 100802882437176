import { ListTodo, BrainCircuit, Bell, Radar } from 'lucide-react';
import {Card, CardContent, CardDescription, CardHeader, CardTitle} from "@/components/ui/card.jsx";
import {Switch} from "@/components/ui/switch.jsx";
import {Separator} from "@/components/ui/separator.jsx";
import {SelectContent, SelectItem, SelectTrigger, SelectValue} from "@/components/ui/select.jsx";
import {Select} from "@radix-ui/react-select";

export const ActionItemsSection = ({ settings, handleSettingChange }) => (
  <Card className="border-none shadow-none">
    <CardContent className="space-y-8">
      <div className={`flex items-center gap-3`}>
      {/* Auto Detection */}
      <div className="w-full space-y-4">
        <div className="flex items-center gap-2">
          <BrainCircuit className="h-5 w-5 text-primary" />
          <h3 className="text-lg font-medium">AI Detection</h3>
        </div>
        <div className="pl-7">
          <div className="max-w-lg">
            <div className="bg-muted/50 p-4 rounded-lg space-y-4">
              <div className="flex items-center justify-between gap-3">
                <Radar className="h-5 w-5 text-primary" />
                <div className={`flex-1`}>
                  <div className="text-sm font-medium">Auto-detection</div>
                  <div className="text-sm text-muted-foreground mt-1">
                    Use AI to identify action items during meetings
                  </div>
                </div>
                <Switch
                  checked={settings.actionItems.autoDetection}
                  onCheckedChange={(checked) =>
                    handleSettingChange('actionItems', 'autoDetection', checked)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Task Creation */}
      <div className="w-full space-y-4">
        <div className="flex items-center gap-2">
          <ListTodo className="h-5 w-5 text-primary" />
          <h3 className="text-lg font-medium">Task Creation</h3>
        </div>
        <div className="pl-7">
          <div className="max-w-lg">
            <div className="p-4 rounded-lg space-y-4">
              <div>
                <div className="text-sm font-medium">Task Creation Behavior</div>
                <div className="text-sm text-muted-foreground mt-1">
                  Configure how action items are converted to tasks
                </div>
              </div>
              <Select
                value={settings.actionItems.taskCreation}
                onValueChange={(value) =>
                  handleSettingChange('actionItems', 'taskCreation', value)
                }
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select task creation behavior"/>
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="automatic">Create Automatically</SelectItem>
                  <SelectItem value="prompt">Ask for Confirmation</SelectItem>
                  <SelectItem value="manual">Manual Creation Only</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>
      </div>
      </div>
      <Separator />

      {/* Reminders */}
      <div className="space-y-4">
        <div className="flex items-center gap-2">
          <Bell className="h-5 w-5 text-primary" />
          <h3 className="text-lg font-medium">Reminders</h3>
        </div>
        <div className="pl-7">
          <div className="max-w-lg">
            <div className="p-4 rounded-lg space-y-4">
              <div>
                <div className="text-sm font-medium">Follow-up Frequency</div>
                <div className="text-sm text-muted-foreground mt-1">
                  Set how often you want to receive reminders
                </div>
              </div>
              <Select
                value={settings.actionItems.reminderFrequency}
                onValueChange={(value) =>
                  handleSettingChange('actionItems', 'reminderFrequency', value)
                }
              >
                <SelectTrigger className="w-48">
                  <SelectValue placeholder="Select frequency"/>
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="daily">Daily Reminders</SelectItem>
                  <SelectItem value="weekly">Weekly Reminders</SelectItem>
                  <SelectItem value="custom">Custom Schedule</SelectItem>
                  <SelectItem value="none">No Reminders</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>
      </div>
    </CardContent>
  </Card>
);
