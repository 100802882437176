import React from 'react';
import { Search,  Check, LayoutDashboard } from 'lucide-react';
import { Input } from "@/components/ui/input";
export const SettingsHeader = ({ searchQuery, setSearchQuery}) => (
  <div className="flex items-center space-x-4 justify-between w-full">
    <div className="flex items-center space-x-4">
      <LayoutDashboard className="h-6 w-6 text-blue-600"/>
      <h1 className="text-xl font-semibold text-primary">Workspace Settings</h1>
    </div>
    <div className="flex flex-1 items-center space-x-4 sm:justify-end">
      <div className="w-full flex items-center space-x-4 max-w-sm">
        <div className="relative flex-1">
          <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground"/>
          <Input
            placeholder="Search settings..."
            className="pl-8"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>
    </div>
  </div>
);
