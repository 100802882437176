import React, { useState } from 'react';
import { Search as SearchIcon, X, ChevronDown, SlidersHorizontal } from 'lucide-react';

const SearchComponent = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState({
    dateRange: 'all',
    eventType: 'all',
    status: 'all'
  });

  const handleSearch = (term, filters) => {
    onSearch({ term, ...filters });
  };

  return (
    <div className='relative w-[60%] '>
      <div className=" flex items-center ring-1 ring-gray-300 rounded-3xl bg-white focus-within:ring-1 focus-within:ring-gray-500">
        <div className="flex-1 flex items-center ps-3  text-gray-400  focus-within:text-gray-600  border-r border-gray-300 focus-within:border-gray-500">
          <SearchIcon className="h-4 w-4" />
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              handleSearch(e.target.value, advancedFilters);
            }}
            placeholder="Search events..."
            className="w-full px-3 py-2 border-0 focus:outline-none text-sm"
          />
          {searchTerm && (
            <button onClick={() => {
              setSearchTerm('');
              handleSearch('', advancedFilters);
            }} className="p-1 hover:bg-gray-100 rounded-full focus:outline-none">
              <X className="h-4 w-4 text-gray-400" />
            </button>
          )}
        </div>

        <button
          onClick={() => setShowAdvanced(!showAdvanced)}
          className="px-3 py-1.5 rounded-2xl flex items-center text-sm text-gray-600 hover:bg-gray-50 "
        >
          <SlidersHorizontal size={20} strokeWidth="2.5" />
        </button>
      </div>

      {showAdvanced && (
        <AdvancedFilters
          filters={advancedFilters}
          setFilters={setAdvancedFilters}
          onApply={(filters) => handleSearch(searchTerm, filters)}
        />
      )}
    </div>
  );
};

const AdvancedFilters = ({ filters, setFilters, onApply }) => (
  <div className="absolute top-full mt-2 w-full bg-white border rounded-lg shadow-lg p-4 z-10">
    <div className="space-y-4">
      <FilterSelect
        label="Date Range"
        value={filters.dateRange}
        onChange={(value) => setFilters({ ...filters, dateRange: value })}
        options={[
          { value: 'all', label: 'All Time' },
          { value: 'today', label: 'Today' },
          { value: 'week', label: 'This Week' },
          { value: 'month', label: 'This Month' }
        ]}
      />

      <FilterSelect
        label="Event Type"
        value={filters.eventType}
        onChange={(value) => setFilters({ ...filters, eventType: value })}
        options={[
          { value: 'all', label: 'All Types' },
          { value: 'meeting', label: 'Meetings' },
          { value: 'task', label: 'Tasks' },
          { value: 'deadline', label: 'Deadlines' }
        ]}
      />

      <FilterSelect
        label="Status"
        value={filters.status}
        onChange={(value) => setFilters({ ...filters, status: value })}
        options={[
          { value: 'all', label: 'All Status' },
          { value: 'upcoming', label: 'Upcoming' },
          { value: 'completed', label: 'Completed' },
          { value: 'cancelled', label: 'Cancelled' }
        ]}
      />

      <div className="flex justify-end pt-2">
        <button
          onClick={() => onApply(filters)}
          className="px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-lg hover:bg-blue-700"
        >
          Apply Filters
        </button>
      </div>
    </div>
  </div>
);

const FilterSelect = ({ label, value, onChange, options }) => (
  <div>
    <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>
    <select
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="w-full p-2 border rounded-md text-sm"
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
);

export default SearchComponent;
