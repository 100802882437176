import React from 'react';
import { Card, CardHeader, CardTitle, CardContent, CardDescription } from "@/components/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import { Separator } from "@/components/ui/separator";
import { Clock, Timer, CalendarDays, Focus } from 'lucide-react';

export const SchedulePreferencesSection = ({ settings, handleSettingChange }) => (
  <Card className="border-none shadow-none">
    <CardContent className="space-y-8 w-full">
      <div className={`flex gap-6 justify-between`}>
      {/* Working Hours */}
      <div className="w-full space-y-4">
        <div className="flex items-center gap-2">
          <Clock className="h-5 w-5 text-primary" />
          <h3 className="text-lg font-medium">Working Hours</h3>
        </div>
        <div className="pl-7">
          <div className="max-w-lg">
            <div className="p-4 rounded-lg space-y-4">
              <div>
                <div className="text-sm font-medium">Available Hours</div>
                <div className="text-sm text-muted-foreground mt-1">
                  Set your available hours for meetings
                </div>
              </div>
              <div className="flex items-center gap-4">
                <Select
                  value={settings.schedule.workingHoursStart}
                  onValueChange={(value) =>
                    handleSettingChange('schedule', 'workingHoursStart', value)
                  }
                >
                  <SelectTrigger className="w-32">
                    <SelectValue placeholder="Start time"/>
                  </SelectTrigger>
                  <SelectContent>
                    {Array.from({length: 24}, (_, i) => (
                      <SelectItem key={i} value={i.toString()}>
                        {`${i.toString().padStart(2, '0')}:00`}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <span className="text-sm text-muted-foreground">to</span>
                <Select
                  value={settings.schedule.workingHoursEnd}
                  onValueChange={(value) =>
                    handleSettingChange('schedule', 'workingHoursEnd', value)
                  }
                >
                  <SelectTrigger className="w-32">
                    <SelectValue placeholder="End time"/>
                  </SelectTrigger>
                  <SelectContent>
                    {Array.from({length: 24}, (_, i) => (
                      <SelectItem key={i} value={i.toString()}>
                        {`${i.toString().padStart(2, '0')}:00`}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Buffer Time */}
      <div className="w-full space-y-4">
        <div className="flex items-center gap-2">
          <Timer className="h-5 w-5 text-primary" />
          <h3 className="text-lg font-medium">Time Management</h3>
        </div>
        <div className="pl-7">
          <div className="max-w-lg">
            <div className=" p-4 rounded-lg space-y-4">
              <div>
                <div className="text-sm font-medium">Buffer Time</div>
                <div className="text-sm text-muted-foreground mt-1">
                  Add breaks between consecutive meetings
                </div>
              </div>
              <Select
                value={settings.schedule.bufferTime}
                onValueChange={(value) =>
                  handleSettingChange('schedule', 'bufferTime', value)
                }
              >
                <SelectTrigger className="w-48">
                  <SelectValue placeholder="Select buffer time"/>
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="0">No buffer</SelectItem>
                  <SelectItem value="5">5 minutes buffer</SelectItem>
                  <SelectItem value="10">10 minutes buffer</SelectItem>
                  <SelectItem value="15">15 minutes buffer</SelectItem>
                  <SelectItem value="30">30 minutes buffer</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>
      </div>
      </div>
      <Separator />

      {/* Preferred Days */}
      <div className="space-y-4 w-full">
        <div className="flex items-center gap-2">
          <CalendarDays className="h-5 w-5 text-primary" />
          <h3 className="text-lg font-medium">Meeting Days</h3>
        </div>
        <div className="pl-7">
          <div className="w-full">
            <div className=" p-4 rounded-lg space-y-4">
              <div>
                <div className="text-sm font-medium">Preferred Meeting Days</div>
                <div className="text-sm text-muted-foreground mt-1">
                  Select days when you prefer to have meetings scheduled
                </div>
              </div>
              <div className="grid grid-cols-4 gap-3">
                {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(day => (
                  <div key={day} className="flex items-center gap-3 bg-muted/50 p-3 rounded-lg justify-between">
                    <span className="text-sm font-medium capitalize">{day}</span>
                    <Switch
                      checked={settings.schedule.preferredDays[day]}
                      onCheckedChange={(checked) =>
                        handleSettingChange('schedule', `preferredDays.${day}`, checked)
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

    </CardContent>
  </Card>
);
