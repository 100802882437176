import {
  Settings,
  Sliders,
  Video,
  Building,
  Zap,
  Tag,
  FolderCog,
  Clock,
  ClipboardCheck,
  Timer, Hash, Pickaxe
} from 'lucide-react';

export const navigationGroups = [
  {
    name: 'General Workspace',
    icon: Settings,
    items: [
      {
        id: 'workspace-details',
        label: 'Settings',
        icon: Hash,
        help: 'Manage basic workspace information and privacy'
      }
    ]
  },
  {
    name: 'Task Extraction',
    icon: Pickaxe,
    items: [
      {
        id: 'detection',
        label: 'Detection Settings',
        icon: Hash,
        help: 'Configure how tasks are automatically detected from various sources'
      },
      {
        id: 'task-properties',
        label: 'Default Properties',
        icon: Hash,
        help: 'Set default properties for newly created tasks'
      },
      {
        id: 'categorization',
        label: 'Auto-categorization',
        icon: Hash,
        help: 'Manage automatic task categorization rules'
      }
    ]
  },
  {
    name: 'Meeting Settings',
    icon: Video,
    items: [
      {
        id: 'schedule',
        label: 'Schedule Preferences',
        icon: Hash,
        help: 'Set your meeting scheduling preferences and availability'
      },
      {
        id: 'action-items',
        label: 'Action Items',
        icon: Hash,
        help: 'Configure how action items are handled in meetings'
      },
      {
        id: 'meeting-defaults',
        label: 'Meeting Defaults',
        icon: Hash,
        help: 'Set default settings for new meetings'
      }
    ]
  },
  {
    name: 'Advanced Settings',
    icon: Settings,
    items: [
      {
        id: 'import-export',
        label: 'Import & Export',
        icon: Hash,
        help: 'Import or export your workspace settings'
      }
    ]
  }
];
