import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Loader2 } from 'lucide-react';
import {useAuth} from "@/contexts/AuthContext.jsx";
import api, {authAPI, tokenManager} from "@/config/axios.js";
import {SessionUtils} from "@/utils/sassion-management.js";

const GoogleCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { updateTokens, checkAuth, setError: setAuthError, setTempUserData, setShowSignupDialog } = useAuth();
  const [status, setStatus] = useState('loading');
  const [message, setMessage] = useState('Processing your login...');

  useEffect(() => {
    const handleCallback = async () => {
      try {
        const urlParams = new URLSearchParams(location.search);
        const code = urlParams.get('code');

        if (!code) {
          setStatus('error');
          setMessage('No authorization code received');
          return;
        }

        setMessage('Authenticating with Google...');
        // Exchange the code for tokens
        const response = await api.post('/auth/google/callback', {
          code,
          redirectUrl: `${window.location.origin}/auth/google/callback`,
          tier: sessionStorage.getItem('planDetails') || 'INDIVIDUAL',
        });
        if (response?.exist === false) {
          setTempUserData(response?.userData)
          setShowSignupDialog(true)
        }
        if (response?.tokens) {
          SessionUtils.setSession(response?.tokens?.accessToken, response?.tokens?.refreshToken, true);
          tokenManager.startAutoRefresh();
          updateTokens(response?.tokens);
          const userData = await checkAuth();
          setMessage('Successfully authenticated!');
          setStatus('success');
          setTimeout(() => {
            if (userData?.isOnboardingDone) {
              navigate('/all-workspaces');
            } else {
              navigate('/onboarding/welcome');
            }
          }, 1000);
        }
      } catch (err) {
        console.error('Authentication Error:', err);
        setStatus('error');
        setMessage(err?.error?.message || err?.message || 'Authentication failed');
        setAuthError(err?.error?.message || err?.message || 'Authentication failed');
        setTimeout(() => navigate('/login'), 3000);
      }
    };

    handleCallback();
  }, [location, navigate, updateTokens, checkAuth, setAuthError]);



  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl p-8 max-w-md w-full space-y-6">
        {/* TaskWeave Logo - Replace with your actual logo */}
        <div className="flex justify-center">
          <div className="w-16 h-16 rounded-full bg-white shadow-md flex items-center justify-center">
            <svg className="w-8 h-8" viewBox="0 0 24 24">
              <path
                fill="#4285F4"
                d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
              />
              <path
                fill="#34A853"
                d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
              />
              <path
                fill="#FBBC05"
                d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
              />
              <path
                fill="#EA4335"
                d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
              />
            </svg>
          </div>
        </div>

        {/* Status Message */}
        <div className="text-center space-y-4">
          {status === 'loading' && (
            <Loader2 className="w-8 h-8 text-blue-500 animate-spin mx-auto" />
          )}

          {status === 'success' && (
            <div className="w-8 h-8 bg-green-100 text-green-500 rounded-full mx-auto flex items-center justify-center">
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
            </div>
          )}

          {status === 'error' && (
            <div className="w-8 h-8 bg-red-100 text-red-500 rounded-full mx-auto flex items-center justify-center">
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>
          )}

          <h2 className={`text-xl font-semibold ${
            status === 'error' ? 'text-red-600' :
              status === 'success' ? 'text-green-600' :
                'text-gray-800'
          }`}>
            {status === 'loading' ? 'Just a moment...' :
              status === 'success' ? 'Success!' :
                'Oops!'}
          </h2>

          <p className="text-gray-600">{message}</p>
        </div>

        {/* Progress Bar */}
        {status === 'loading' && (
          <div className="relative w-full h-1.5 bg-gray-200 rounded-full overflow-hidden">
            <div className="absolute top-0 left-0 h-full w-full bg-blue-500 animate-progress"></div>
          </div>
        )}

        {/* Redirect Message */}
        {(status === 'success' || status === 'error') && (
          <p className="text-sm text-gray-500 text-center">
            {status === 'success' ?
              'Redirecting you to TaskWeave...' :
              'Redirecting you back to login...'}
          </p>
        )}
      </div>
    </div>
  );
};

export default GoogleCallback;
