import { create } from 'zustand';
import api from "@/config/axios.js";

export const useProfileStore = create((set, get) => ({
  onboardingProfile: {
    firstName: '',
    lastName: '',
    email: '',
    bio: '',
    jobTitle: '',
    department: '',
    industry:'',
    location: '',
    timeZone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || 'UTC',
    profilePicture: null,
    profilePictureUrl: null
  },
  onboardingPreference: null,
  isLoading: false,
  error: null,
  subscription: null,
  sessions: null,
  workspaces: null,
  currentWorkspace: null,
  setWorkspaces: (workspaces) => set({ workspaces }),
  setCurrentWorkspace: (workspace) => set({ currentWorkspace: workspace }),
  updateWorkspaces: (workspace) => {
    const workspaces = get().workspaces.map((w) => {
      if (w.id === workspace.id) {
        return workspace;
      }
      return w;
    });
    set({ workspaces });
  },
  updateCurrentWorkspace: (workspace) => {
    set({ currentWorkspace: workspace });
  },
  setError: (error) => set({ error }),

  setLoading: (isLoading) => set({ isLoading }),

  getOnboardingProfile: async () => {
    try {
      set({ isLoading: true, error: null });
      const response = await api.get('/profile/onboarding/profile');
      set({ onboardingProfile: response, isLoading: false });
    } catch (error) {
      set({ error: error.message, isLoading: false });
      console.error('Error:', error);
    }
  },

  updateProfileField: (field, value) => {
    set((state) => ({
      onboardingProfile: {
        ...state.onboardingProfile,
        [field]: value
      }
    }));
  },

  updateOnboardingProfile: async () => {
    try {
      set({ isLoading: true, error: null });
      const currentProfile = get().onboardingProfile;
      const response = await api.post('/profile/onboarding/profile', currentProfile);
      set({ onboardingProfile: response, isLoading: false });
    } catch (error) {
      set({ error: error.message, isLoading: false });
      console.error('Error:', error);
    }
  },

  getOnboardingPreference: async () => {
    try {
      set({ isLoading: true, error: null });
      const response = await api.get('/profile/onboarding/preference');
      set({ onboardingPreference: response, isLoading: false });
    } catch (error) {
      set({ error: error.message, isLoading: false });
      console.error('Error:', error);
    }
  },

  updatePreferenceField: (field, value) => {
    set((state) => ({
      onboardingPreference: {
        ...state.onboardingPreference,
        [field]: value
      }
    }));
  },

  updateOnboardingPreference: async () => {
    const previousState = get().onboardingPreference;
    try {
      set({ isLoading: true, error: null });
      const currentPreference = get().onboardingPreference;
      const data = await api.post('/profile/onboarding/preference', currentPreference);
      set((state) => ({
        onboardingPreference: {
          ...state.onboardingPreference,
          ...data
        },
        onboardingProfile: {
          ...state.onboardingProfile,
          ...data
        },
        isLoading: false
      }));
    } catch (error) {
      set({ onboardingPreference: previousState });
      set({ error: error.message, isLoading: false });
      console.error('Error:', error);
    }
  },

  createSubscription: async (data) => {
    try {
      set({ isLoading: true, error: null });
      const response = await api.post('/profile/onboarding/subscription', data);
      set({ subscription: response, isLoading: false });
    } catch (error) {
      set({ error: error.message, isLoading: false });
      console.error('Error:', error);
    }
  },

  updateProfilePicture: async (file) => {
    try {
      const currentProfile = get().onboardingProfile;
      set({ isLoading: true, error: null });
      const formData = new FormData();
      formData.append('profilePicture', file);
      const response = await api.post('/profile/upload-picture', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      set({ onboardingProfile: { ...currentProfile, profilePicture: file, avatar: response.thumbnailUrl, profilePictureUrl: response.thumbnailUrl, ...response }, isLoading: false });
    } catch (error) {
      set({ error: error.message, isLoading: false });
      console.error('Error:', error);
    }
  },

  getSession: async () => {
    try {
      set({ isLoading: true, error: null });
      const response = await api.get('/profile/sessions');
      set({ sessions: response, isLoading: false });
    } catch (error) {
      set({ error: error.message, isLoading: false });
      console.error('Error:', error);
    }
  }
}));
