import React from "react";
import { Link } from "react-router-dom";
import { Menu } from "@headlessui/react";
import {
  LayoutDashboard,
  LogOut,
  Settings,
} from "lucide-react";
import { useAuth } from "@/contexts/AuthContext";
import ComboboxDemo from "@/components/ui/comboBoxWorkspace.jsx";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { getWorkspacePath } from "@/router/all.routers";

const MenuItem = ({ icon: Icon, children, ...props }) => (
  <Menu.Item>
    {({ active }) => (
      <div
        className={`flex items-center rounded-lg gap-2 px-4 py-2 text-sm text-gray-700 ${
          active ? "bg-gray-100" : ""
        }`}
        {...props}
      >
        {Icon && <Icon size={16} />}
        {children}
      </div>
    )}
  </Menu.Item>
);

const ProfileHeader = ({ user }) => {
  const initials = `${user?.firstName?.[0] || ""}${user?.lastName?.[0] || ""}`.toUpperCase();

  return (
    <div className="p-4 border-b border-gray-200 flex items-center gap-4">
      <div className="inline-flex items-center size-12 rounded-full ring-2 ring-gray-300 ring-offset-2 justify-center">
        <Avatar>
          <AvatarImage
            className="h-12 w-12"
            src={user?.avatar}
            alt={`${user?.firstName}'s profile`}
          />
          <AvatarFallback>{initials}</AvatarFallback>
        </Avatar>
      </div>
      <div>
        <h2 className="text-md font-semibold text-gray-900 truncate">
          {user?.firstName ? `Hi, ${user.firstName}!` : 'Welcome!'}
        </h2>
        <p className="text-sm text-gray-500">{user?.email}</p>
      </div>
    </div>
  );
};

const ProfileDropdown = ({ workspace }) => {
  const { logout, user } = useAuth();

  const handleWorkspaceSelect = (workspaceId) => {
    console.log('Selected workspace:', workspaceId);
    // Add workspace selection logic here
  };

  return (
    <Menu as="div" className="relative h-8">
      <Menu.Button className="inline-flex items-center size-8 rounded-full ring-2 ring-gray-300 ring-offset-2 justify-center">
        <Avatar>
          <AvatarImage
            src={user?.avatar}
            alt={`${user?.firstName}'s profile`}
          />
          <AvatarFallback>
            {`${user?.firstName?.[0] || ""}${user?.lastName?.[0] || ""}`.toUpperCase()}
          </AvatarFallback>
        </Avatar>
      </Menu.Button>

      <Menu.Items className="absolute right-0 z-10 mt-2 min-w-72 origin-top-right rounded-2xl bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
        <ProfileHeader user={user} />

        <ComboboxDemo
          label="Select Workspace"
          workspaces={user?.workspaces}
          onWorkspaceSelect={handleWorkspaceSelect}
        />

        <div className="border-t border-gray-200 my-2" />

        <div className="px-2 pb-4 flex flex-col gap-2">
          <MenuItem icon={LayoutDashboard}>
            <Link
              to={getWorkspacePath('/:shortCode/dashboard', workspace?.shortCode)}
              className="w-full"
            >
              Dashboard
            </Link>
          </MenuItem>

          <MenuItem icon={Settings}>
            <Link
              to="/settings"
              className="w-full"
            >
              User Settings
            </Link>
          </MenuItem>

          <MenuItem icon={LogOut}>
            <button
              onClick={logout}
              className="w-full text-left"
            >
              Sign out
            </button>
          </MenuItem>
        </div>
      </Menu.Items>
    </Menu>
  );
};

export default ProfileDropdown;
