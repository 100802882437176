import React, {useEffect, useRef, useState} from "react";
import {
  ArrowLeft,
  Bell,
  Calendar,
  Clock, Command,
  Filter,
  FolderKanban, HelpCircle,
  List,
  Mail,
  MessageSquare, PieChart, Pin, PinOff, Plus,
  Search,
  Settings,
  Users,
  Video
} from "lucide-react";
import { NotificationCenter } from "@/components/common/notifications/NotificationCenter.jsx";
import ProfileDropdown from "./profileDropdown.jsx";
import {Link, Link as RouterLink, useLocation} from "react-router-dom";
import {getWorkspacePath, sidebarRoutes} from "@/router/all.routers.jsx";
import {useAuth} from "@/contexts/AuthContext.jsx";
import SearchComponent from "../SearchComponent.jsx";
import {useProfileStore} from "@/store/slices/profileSlice.js";

export default function AuthLayout({ children,  childrenHeader, classes = '', bodyClass = '', showSidebar = true }) {
  const currentPath = useLocation().pathname;
  const {isSubmenuFixed, setIsSubmenuFixed} = useAuth();
  const [showNotifications, setShowNotifications] = React.useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const hoverTimeoutRef = useRef(null);
  const [isSearchVisible, setSearchVisible] = useState(false);
  const {currentWorkspace} = useProfileStore();

  useEffect(() => {
    return () => {
      if (hoverTimeoutRef.current) {
        clearTimeout(hoverTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isSubmenuFixed && !currentPathHasSubmenu()) {
      setIsSubmenuFixed(false);
      setSelectedItem(null);
      setHoveredItem(null);
    }else if (currentPathHasSubmenu() && isSubmenuFixed) {
      setSelectedItem(currentPath);
    }
  }, [currentPath]);

  const currentPathHasSubmenu = () => {
    return hasSubmenuContent(currentPath);
  };

  const MenuItem = ({ icon: Icon, label, path }) => {
    const navigatePath = getWorkspacePath(path, currentWorkspace?.shortCode);

    return (
      <RouterLink
        to={navigatePath}
        className="flex items-center gap-2 overflow-hidden rounded-md px-2 py-1.5 text-left text-xs hover:bg-sidebar-accent hover:text-sidebar-accent-foreground group/menu-item relative"
      >
        <Icon className="h-4 w-4" />
        <span className="truncate">{label}</span>
      </RouterLink>
    );
  };

  const MenuGroup = ({ title, children }) => (
    <div className="space-y-1">
      <h3 className="text-xs font-medium text-gray-500 px-2 h-8 flex items-center">
        {title}
      </h3>
      {children}
    </div>
  );

  const hasSubmenuContent = (path) => {
    // Only show submenu for specific routes that have submenu content
    const routesWithSubmenus = ['/tasks', '/calendar', '/integrations', '/settings', '/events'];
    return routesWithSubmenus.includes(path);
  };

  const handleMenuClick = () => {
    if (!isSubmenuFixed) {
      // Pinning the menu
      setIsSubmenuFixed(true);
      setSelectedItem(hoveredItem || currentPath);
      localStorage.setItem('isSubmenuFixed', 'true');
    } else {
      // Unpinning the menu
      setIsSubmenuFixed(false);
      setSelectedItem(null);
      localStorage.setItem('isSubmenuFixed', 'false');
      // Don't clear hoveredItem immediately to prevent flickering
      setTimeout(() => {
        setHoveredItem(null);
      }, 100);
    }
  };

  const handleMouseEnter = (path) => {
    // Only set hover state if the path has submenu content
    if (hasSubmenuContent(path)) {
      if (!isSubmenuFixed) {
        setHoveredItem(path);
      } else if (selectedItem !== path) {
        setHoveredItem(path);
        setSelectedItem(path);
      }
    } else {
      // Clear hover state for items without submenus
      setHoveredItem(null);
    }
  };

  const handleMouseLeave = () => {
    if (!isSubmenuFixed) {
      setHoveredItem(null);
    }
  };

  const handleParentClick = (path) => {
    const makePath = getWorkspacePath(path, currentWorkspace?.shortCode);
    setSelectedItem(makePath);
    if (isSubmenuFixed) {
      // Only keep submenu fixed if new path has submenu content
      if (!hasSubmenuContent(makePath)) {
        setIsSubmenuFixed(false);
        setSelectedItem(null);
      } else {
        setHoveredItem(makePath);
      }
    }
  };

  const handleSearchResult = (searchParams) => {
    //TODO Implement search logic here
  };

  const searchClick = () => {
    setSearchVisible(!isSearchVisible); // Toggle the visibility
  };

  const SubmenuContent = ({ routePath }) => {
    const getSubmenuContent = () => {
      switch (routePath) {
        case '/tasks':
          return (
            <>
              <MenuGroup title="Task Views">
                <MenuItem icon={List} label="List View" path="/tasks/list" />
                <MenuItem icon={FolderKanban} label="Kanban Board" path="/tasks/board" />
                <MenuItem icon={Calendar} label="Calendar View" path="/tasks/calendar" />
                <MenuItem icon={Clock} label="Timeline View" path="/tasks/timeline" />
              </MenuGroup>
              <MenuGroup title="Filters">
                <MenuItem icon={Clock} label="Due Today" path="/tasks/due-today" />
                <MenuItem icon={Filter} label="High Priority" path="/tasks/priority" />
                <MenuItem icon={Users} label="Assigned to Me" path="/tasks/assigned" />
                <MenuItem icon={Clock} label="Overdue" path="/tasks/overdue" />
              </MenuGroup>
              <MenuGroup title="Organization">
                <MenuItem icon={List} label="Labels" path="/tasks/labels" />
                <MenuItem icon={FolderKanban} label="Categories" path="/tasks/categories" />
                <MenuItem icon={Users} label="Projects" path="/tasks/projects" />
              </MenuGroup>
              <MenuGroup title="Sources">
                <MenuItem icon={Mail} label="Email Tasks" path="/tasks/email" />
                <MenuItem icon={MessageSquare} label="Chat Tasks" path="/tasks/chat" />
                <MenuItem icon={Video} label="Meeting Tasks" path="/tasks/meetings" />
              </MenuGroup>
            </>
          );

        case '/calendar':
          return (
            <>
              <MenuGroup title="Calendar Views">
                <MenuItem icon={Calendar} label="Month View" path="/calendar/month" />
                <MenuItem icon={Calendar} label="Week View" path="/calendar/week" />
                <MenuItem icon={Calendar} label="Day View" path="/calendar/day" />
                <MenuItem icon={Calendar} label="Agenda View" path="/calendar/agenda" />
              </MenuGroup>
              <MenuGroup title="Events">
                <MenuItem icon={Clock} label="Upcoming" path="/calendar/upcoming" />
                <MenuItem icon={Users} label="Team Events" path="/calendar/team" />
                <MenuItem icon={Video} label="Meetings" path="/calendar/meetings" />
                <MenuItem icon={Clock} label="Recurring" path="/calendar/recurring" />
              </MenuGroup>
              <MenuGroup title="Task Integration">
                <MenuItem icon={List} label="Task Deadlines" path="/calendar/deadlines" />
                <MenuItem icon={Clock} label="Time Blocks" path="/calendar/time-blocks" />
                <MenuItem icon={Users} label="Team Availability" path="/calendar/availability" />
              </MenuGroup>
            </>
          );

        case '/integrations':
          return (
            <>
              <MenuGroup title="Email Services">
                <MenuItem icon={Mail} label="Gmail" path="/integrations/gmail" />
                <MenuItem icon={Mail} label="Outlook" path="/integrations/outlook" />
                <MenuItem icon={Settings} label="Email Settings" path="/integrations/email-settings" />
              </MenuGroup>
              <MenuGroup title="Chat Platforms">
                <MenuItem icon={MessageSquare} label="Slack" path="/integrations/slack" />
                <MenuItem icon={MessageSquare} label="MS Teams" path="/integrations/teams" />
                <MenuItem icon={Settings} label="Chat Settings" path="/integrations/chat-settings" />
              </MenuGroup>
              <MenuGroup title="Calendar Apps">
                <MenuItem icon={Calendar} label="Google Calendar" path="/integrations/google-calendar" />
                <MenuItem icon={Calendar} label="Outlook Calendar" path="/integrations/outlook-calendar" />
                <MenuItem icon={Settings} label="Calendar Settings" path="/integrations/calendar-settings" />
              </MenuGroup>
              <MenuGroup title="Storage">
                <MenuItem icon={FolderKanban} label="Google Drive" path="/integrations/gdrive" />
                <MenuItem icon={FolderKanban} label="OneDrive" path="/integrations/onedrive" />
                <MenuItem icon={Settings} label="Storage Settings" path="/integrations/storage-settings" />
              </MenuGroup>
            </>
          );

        case '/dashboard':
          return (
            <>
              <MenuGroup title="Task Analytics">
                <MenuItem icon={PieChart} label="Completion Rates" path="/analytics/completion" />
                <MenuItem icon={Clock} label="Time Analysis" path="/analytics/time" />
                <MenuItem icon={Filter} label="Priority Distribution" path="/analytics/priority" />
                <MenuItem icon={Users} label="Assignment Analysis" path="/analytics/assignments" />
              </MenuGroup>
              <MenuGroup title="Time Tracking">
                <MenuItem icon={Clock} label="Time Reports" path="/analytics/time-reports" />
                <MenuItem icon={Users} label="Team Productivity" path="/analytics/productivity" />
                <MenuItem icon={Calendar} label="Calendar Usage" path="/analytics/calendar-usage" />
              </MenuGroup>
              <MenuGroup title="Source Analysis">
                <MenuItem icon={Mail} label="Email Metrics" path="/analytics/email-metrics" />
                <MenuItem icon={MessageSquare} label="Chat Analytics" path="/analytics/chat-analytics" />
                <MenuItem icon={Video} label="Meeting Stats" path="/analytics/meeting-stats" />
              </MenuGroup>
              <MenuGroup title="Reports">
                <MenuItem icon={List} label="Custom Reports" path="/analytics/custom" />
                <MenuItem icon={Clock} label="Scheduled Reports" path="/analytics/scheduled" />
                <MenuItem icon={FolderKanban} label="Export Data" path="/analytics/export" />
              </MenuGroup>
            </>
          );
        case '/events':
          return (
            <>
              <MenuGroup title="Event Management">
                <MenuItem icon={Calendar} label="All Events" path="/events/all" />
                <MenuItem icon={Clock} label="Upcoming Events" path="/events/upcoming" />
                <MenuItem icon={Users} label="My Events" path="/events/my-events" />
                <MenuItem icon={Video} label="Virtual Events" path="/events/virtual" />
              </MenuGroup>
              <MenuGroup title="Event Types">
                <MenuItem icon={Video} label="Meetings" path="/events/meetings" />
                <MenuItem icon={Users} label="Team Sessions" path="/events/team-sessions" />
                <MenuItem icon={MessageSquare} label="Workshops" path="/events/workshops" />
                <MenuItem icon={Calendar} label="Recurring Events" path="/events/recurring" />
              </MenuGroup>
              <MenuGroup title="Planning">
                <MenuItem icon={List} label="Event Templates" path="/events/templates" />
                <MenuItem icon={Users} label="Guest Management" path="/events/guests" />
                <MenuItem icon={Settings} label="Resources" path="/events/resources" />
                <MenuItem icon={Clock} label="Scheduling" path="/events/scheduling" />
              </MenuGroup>
              <MenuGroup title="Analytics">
                <MenuItem icon={PieChart} label="Attendance" path="/events/attendance" />
                <MenuItem icon={Clock} label="Duration Stats" path="/events/duration-stats" />
                <MenuItem icon={Filter} label="Engagement" path="/events/engagement" />
              </MenuGroup>
            </>
          );

        case '/automation':
          return (
            <>
              <MenuGroup title="Workflow Automation">
                <MenuItem icon={Settings} label="All Workflows" path="/automation/workflows" />
                <MenuItem icon={Plus} label="Create Workflow" path="/automation/create" />
                <MenuItem icon={List} label="Templates" path="/automation/templates" />
                <MenuItem icon={Clock} label="Scheduled" path="/automation/scheduled" />
              </MenuGroup>
              <MenuGroup title="Rules & Triggers">
                <MenuItem icon={Settings} label="Task Rules" path="/automation/task-rules" />
                <MenuItem icon={Calendar} label="Event Rules" path="/automation/event-rules" />
                <MenuItem icon={Bell} label="Notifications" path="/automation/notifications" />
                <MenuItem icon={Clock} label="Time Triggers" path="/automation/time-triggers" />
              </MenuGroup>
              <MenuGroup title="Actions">
                <MenuItem icon={Mail} label="Email Actions" path="/automation/email-actions" />
                <MenuItem icon={MessageSquare} label="Chat Actions" path="/automation/chat-actions" />
                <MenuItem icon={Calendar} label="Calendar Actions" path="/automation/calendar-actions" />
                <MenuItem icon={Users} label="Team Actions" path="/automation/team-actions" />
              </MenuGroup>
              <MenuGroup title="Management">
                <MenuItem icon={PieChart} label="Analytics" path="/automation/analytics" />
                <MenuItem icon={Clock} label="History" path="/automation/history" />
                <MenuItem icon={Settings} label="Settings" path="/automation/settings" />
              </MenuGroup>
            </>
          );

        default:
          return (
            <MenuGroup title="Options">
              <MenuItem icon={List} label="Overview" path={`${routePath}/overview`}/>
              <MenuItem icon={Settings} label="Settings" path={`${routePath}/settings`}/>
            </MenuGroup>
          );
      }
    };
    return (
      <div className="flex flex-col h-full">
        <div className={`p-3 flex justify-between items-center`}>
          <div className="grid flex-1 text-left text-sm leading-tight">
            <span className="truncate font-semibold">Acme Inc</span>
            <span className="truncate text-xs">Enterprise</span>
          </div>
          <div
            className={`inline-flex items-center rounded-md justify-center text-sm font-semibold cursor-pointer text-gray-900 text-opacity-50 hover:text-opacity-100`}
            onClick={handleMenuClick}
          >
            {isSubmenuFixed ? <PinOff className={`w-5`}/> : <Pin className={`w-5`}/>}

          </div>
        </div>
        <div className="flex flex-col gap-4 py-4 px-2.5 overflow-y-auto">
          {getSubmenuContent()}
        </div>
      </div>
    );
  };

  return (
    <>

      <header className={`z-40 pb-4 sticky top-0 bg-gray-50 `}>
        <div className={`bg-white  flex items-center justify-between py-2 gap-3 pe-3  ${ isSubmenuFixed && currentPathHasSubmenu()  ? 'ps-64' : 'ps-16'  } ${classes}`}>
          {isSearchVisible ? (
            <div className="relative me-auto flex flex-1 items-center justify-between space-x-3">
              <button
                className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg focus:outline-none"
                aria-label="Notifications"
                onClick={searchClick}
              >
                <ArrowLeft size={20} strokeWidth="2.5"/>
              </button>
              <SearchComponent onSearch={handleSearchResult} />
            </div>
          ):(
            <>
              <div className="flex flex-1 items-center ">{childrenHeader}</div>
            </>
          )}
          <div className="flex items-center space-x-3">
            {!isSearchVisible &&
              <button
                className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg focus:outline-none"
                aria-label="Notifications"
                onClick={searchClick}
              >
                <Search size={20} strokeWidth="2.5"/>
              </button>
            }
            <button
              className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg focus:outline-none"
              aria-label="Notifications"
              onClick={() => setShowNotifications(!showNotifications)}
            >
              <Bell size={20} strokeWidth="2.5"/>
            </button>
            {currentWorkspace && (
              <ProfileDropdown workspace={currentWorkspace} />
            )}
          </div>
        </div>
      </header>
      {showSidebar && (
        <aside className="w-12 top-0 fixed z-40 h-full">
        <div className="bg-gray-900 flex flex-col items-center py-2 h-full">
          <nav className="flex-1 w-full">
            <ul className="flex flex-col items-center space-y-4">
              <li>
                <div>
                  <Link to={`/all-workspaces`}
                        className="inline-flex items-center size-12 rounded-md justify-center text-sm font-semibold cursor-pointer mx-2">
                    <div
                      className="flex aspect-square size-10 items-center justify-center rounded-lg  text-sidebar-primary-foreground">
                      <Command className="w-6 h-6"/>
                    </div>
                  </Link>
                </div>
              </li>
              {sidebarRoutes.map((item) => (
                item.location === "sideBar" && (
                  <li
                    key={getWorkspacePath(item.path, currentWorkspace?.shortCode)}
                    className="w-full relative group"
                  >
                    <RouterLink
                      to={getWorkspacePath(item.path, currentWorkspace?.shortCode)}
                      className={`flex items-center justify-center py-3 text-gray-400 ${
                        currentPath === item.path ? 'text-white bg-gray-800' : 'hover:text-white hover:bg-gray-800'
                      } rounded-lg `}
                      title={item.label}
                      onMouseEnter={() => handleMouseEnter(item.path)}
                      onMouseLeave={handleMouseLeave}
                      onClick={() => handleParentClick(item.path)}
                    >
                      <item.icon className="w-6 h-6"/>
                    </RouterLink>

                    {/* Only show hover bridge for items with submenus */}
                    {!isSubmenuFixed && hasSubmenuContent(item.path) && (
                      <div
                        className="hidden group-hover:block fixed left-16 w-4 h-screen top-0 bg-transparent"
                        onMouseEnter={() => handleMouseEnter(item.path)}
                      />
                    )}

                    {/* Submenu */}
                    {hasSubmenuContent(item.path) &&
                      (hoveredItem === item.path || (isSubmenuFixed && selectedItem === item.path)) && (
                        <div
                          className={`z-[50] fixed left-12 top-0 w-48 bg-white border-r border-gray-200 h-full overflow-y-auto transition-transform duration-300 ${
                            isSubmenuFixed ? 'translate-x-0' : ''
                          }`}
                          onMouseEnter={() => handleMouseEnter(item.path)}
                          onMouseLeave={handleMouseLeave}
                        >
                          <SubmenuContent routePath={item.path}/>
                        </div>
                      )}
                  </li>
                )
              ))}
            </ul>
          </nav>

          <div className="mt-auto mb-4">
            <RouterLink
              to="/help"
              className="flex items-center justify-center p-3 text-gray-400 hover:text-white hover:bg-gray-800 rounded-lg mx-2"
              title="Help & Support"
            >
              <HelpCircle className="w-6 h-6"/>
            </RouterLink>
          </div>
        </div>
      </aside>
      )}
      {showNotifications && (
        <NotificationCenter onClose={() => setShowNotifications(false)}/>
      )}
      <div className={` ${ isSubmenuFixed && currentPathHasSubmenu() ? 'ps-64' : showSidebar ? 'ps-16' : ''  } pe-4 min-h-without-header ${bodyClass}`}>
        {children}
      </div>
    </>
  );
}
