import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useIntegration } from "@/contexts/IntegrationContext.jsx";
import { getWorkspacePath } from "@/router/all.routers.jsx";
import { useProfileStore } from "@/store/slices/profileSlice.js";

const ConnectCallback = () => {
  const { currentWorkspace } = useProfileStore();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setCallback } = useIntegration();

  const code = searchParams.get("code");
  const state = searchParams.get("state"); // platform identifier
  const error_param = searchParams.get("error");

  useEffect(() => {
    setCallback({ code, state, error: error_param });
    navigate(
      getWorkspacePath("/:shortCode/integrations", currentWorkspace?.shortCode),
    );
  }, [code, state, error_param]);

  return <></>;
};

export default ConnectCallback;
