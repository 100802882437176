import React from 'react';
import { Navigate } from 'react-router-dom';
import { useProfileStore } from '@/store/slices/profileSlice.js';

const WorkspaceRoute = ({ component: Component, ...rest }) => {
  const { currentWorkspace } = useProfileStore();

  if (!currentWorkspace?.shortCode) {
    return <Navigate to="/all-workspaces" />;
  }

  return <Component {...rest} />;
};

export default WorkspaceRoute;
