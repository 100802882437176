import {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import { authAPI, tokenManager } from "../config/axios";
import { SessionUtils } from "@utils/sassion-management.js";
import { useNavigate, useLocation } from "react-router-dom";
import { publicRoutes } from "../router/public-routes.js";
import { useGoogleLogin } from "@react-oauth/google";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog.jsx";
import { Alert, AlertDescription } from "@/components/ui/alert.jsx";
import { Button } from "@/components/ui/button.jsx";
import { Loader2 } from "lucide-react";
import {useProfileStore} from "@/store/slices/profileSlice.js";
import {getCurrentWorkspace} from "@/utils/common.js";

const AuthContext = createContext(null);
const selectGetOnboardingProfile = (state) => state.getOnboardingProfile;
const selectUpdateProfileField = (state) => state.updateProfileField;

export const AuthProvider = ({ children }) => {
  const getOnboardingProfile = useProfileStore(selectGetOnboardingProfile);
  const updateProfileField = useProfileStore(selectUpdateProfileField);
  const setWorkspaces = useProfileStore((state) => state.setWorkspaces);
  const setCurrentWorkspace = useProfileStore((state) => state.setCurrentWorkspace);

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [tokens, setTokens] = useState({
    accessToken: null,
    refreshToken: null,
  });
  const [showSignupDialog, setShowSignupDialog] = useState(false);
  const [tempUserData, setTempUserData] = useState(null);
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [lockoutUntil, setLockoutUntil] = useState(null);
  const [selectedWorkspace, setsSelectedWorkspace] = useState();
  const [isSubmenuFixed, setIsSubmenuFixed] = useState(localStorage.getItem("isSubmenuFixed") === "true");

  const navigate = useNavigate();
  const location = useLocation();

  // Security Constants
  const MAX_LOGIN_ATTEMPTS = 5;
  const LOCKOUT_DURATION = 15 * 60 * 1000; // 15 minutes

  useEffect(() => {
    const selectedWorkspace = user?.workspaces.find(
      (workspace) => workspace?.isCurrentlySelected,
    );
    if (selectedWorkspace) {
      setsSelectedWorkspace(selectedWorkspace);
    }
  }, [user?.workspaces]);

  const checkAccountExists = async (email) => {
    try {
      const response = await authAPI.checkAccount(email);
      return response.exists;
    } catch (err) {
      console.error("Account check failed:", err);
      return false;
    }
  };

  const handleGoogleSignup = async (userData) => {
    try {
      setLoading(true);
      // setError(null);

      // Additional user data for signup
      const signupData = {
        ...userData,
        signupMethod: "google",
        agreeToTerms: true, // You might want to add this to the dialog
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        tier: sessionStorage.getItem("planDetails") || "INDIVIDUAL",
        isTrailActive: ["INDIVIDUAL", "PROFESSIONAL"].includes(sessionStorage.getItem("planDetails"))
      };
      await sendToBackend(signupData);
    } catch (err) {
      setError(err.response?.data?.message || "Signup failed");
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // Memoize checkAuth to prevent unnecessary recreations
  const checkAuth = useCallback(async () => {
    try {
      const session = SessionUtils.getSession();
      if (!session) {
        setLoading(false);
        return;
      }

      const { user: userData } = await authAPI.verifyToken();
      await getOnboardingProfile();
      updateProfileField('user', userData)
      updateProfileField('workspaces', userData?.workspaces)
      setWorkspaces(userData?.workspaces)
      setCurrentWorkspace(getCurrentWorkspace(userData?.workspaces))
      updateProfileField('emailVerified', userData?.emailVerified)
      updateProfileField('isOnboardingDone', userData?.isOnboardingDone)
      updateProfileField('isTrailActive', userData?.isTrailActive)
      updateProfileField('subscription', userData?.subscription)
      setIsAuthenticated(true);
      setUser(userData);
      tokenManager.startAutoRefresh();
      if (session.isRemembered) {
        SessionUtils.extendSession();
      }

      setTempUserData(null);
      setShowSignupDialog(false);
      if (publicRoutes.includes(location.pathname)) {
        return userData;
      }
      if (!userData?.isOnboardingDone) {
        navigate("/onboarding/welcome", { replace: true });
      }
      return userData;
    } catch (err) {
      // SessionUtils.clearSession();
      console.error("Check auth error:", err);
      setError("Session expired. Please login again.");
      setIsAuthenticated(false);
      setUser(null);
    } finally {
      setLoading(false);
    }
  }, [navigate, location.pathname]);

  // Handle token management
  const updateTokens = useCallback(({ accessToken, refreshToken }) => {
    setTokens({ accessToken, refreshToken });
    setIsAuthenticated(true);
  }, []);

  const handleLockout = useCallback(() => {
    const now = Date.now();
    setLockoutUntil(now + LOCKOUT_DURATION);
    setTimeout(() => setLockoutUntil(null), LOCKOUT_DURATION);
  });

  // Authentication methods
  const login = useCallback(
    async (email, password, rememberMe = false) => {
      try {
        setError(null);
        const { tokens: newTokens } = await authAPI.login(
          email,
          password,
          rememberMe,
        );
        updateTokens(newTokens);
        return await checkAuth();
      } catch (err) {
        setLoginAttempts((prev) => prev + 1);
        const errorMessage = err.response?.data?.message || "Login failed";
        setError(errorMessage);
        throw err;
      }
    },
    [checkAuth, updateTokens],
  );

  const sendToBackend = useCallback(
    async (userData) => {
      try {
        const newTokens = await authAPI.google(userData);
        updateTokens(newTokens);
        return await checkAuth();
      } catch (error) {
        console.error("Google login error:", error);
        throw error.response?.data;
      }
    },
    [checkAuth, updateTokens],
  );

  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    ux_mode: "redirect",
    redirect_uri: `${window.location.origin}/auth/google/callback`,
  });

  const register = useCallback(
    async (userData) => {
      try {
        setError(null);
        const { tokens: newTokens } = await authAPI.register(userData);
        updateTokens(newTokens);
        return await checkAuth();
      } catch (err) {
        const errorMessage =
          err.response?.data?.message || "Registration failed";
        setError(errorMessage);
        throw err;
      }
    },
    [checkAuth, updateTokens],
  );

  const logout = useCallback(async () => {
    try {
      await authAPI.logout();
      authAPI.clearGoogleSession();
    } catch (err) {
      console.error("Logout error:", err);
    } finally {
      SessionUtils.clearSession();
      setUser(null);
      setIsAuthenticated(false);
      setTokens({ accessToken: null, refreshToken: null });
      window.location.href = "/";
    }
  }, []);

  // Initial auth check and token refresh setup
  useEffect(() => {
    console.log("Checking auth...");
    checkAuth();
  }, [checkAuth]);

  useEffect(() => {
    const session = SessionUtils.getSession();
    if (session) {
      tokenManager.startAutoRefresh();
    }
    return () => tokenManager.stopAutoRefresh();
  }, []);

  // Memoize context value to prevent unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      user,
      setUser,
      loading,
      error,
      login,
      checkAccountExists,
      register,
      logout,
      isAuthenticated,
      checkAuth,
      googleLogin,
      tokens,
      showSignupDialog,
      tempUserData,
      loginAttempts,
      updateTokens,
      setError,
      setTempUserData,
      setShowSignupDialog,
      selectedWorkspace,
      setsSelectedWorkspace,
      isSubmenuFixed, setIsSubmenuFixed
    }),
    [
      user,
      loading,
      error,
      login,
      checkAccountExists,
      register,
      logout,
      isAuthenticated,
      checkAuth,
      googleLogin,
      tokens,
      showSignupDialog,
      tempUserData,
      loginAttempts,
      updateTokens,
      setError,
      setTempUserData,
      setShowSignupDialog,
      user?.workspaces,
      isSubmenuFixed, setIsSubmenuFixed
    ],
  );

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader2 className="h-12 w-12 animate-spin" />
      </div>
    );
  }

  return (
    <AuthContext.Provider value={contextValue}>
      {children}

      {/* Signup Dialog */}
      <Dialog open={showSignupDialog} onOpenChange={setShowSignupDialog}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>Complete Your Registration</DialogTitle>
          </DialogHeader>

          <div className="flex flex-col space-y-4 py-4">
            {error && (
              <Alert variant="destructive">
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}

            {tempUserData && (
              <div className="flex flex-col space-y-4">
                <div className="flex items-center space-x-4">
                  {tempUserData.picture && (
                    <img
                      src={tempUserData.picture}
                      alt={tempUserData.name}
                      className="h-12 w-12 rounded-full"
                    />
                  )}
                  <div>
                    <p className="font-medium">{tempUserData.name}</p>
                    <p className="text-sm text-gray-500">
                      {tempUserData.email}
                    </p>
                  </div>
                </div>

                <p className="text-sm text-gray-600">
                  {`This Google account isn't registered with TaskWeave.
                                Would you like to create a new account?`}
                </p>

                <div className="text-sm text-gray-500">
                  {`By creating an account, you agree to TaskWeave's`}{" "}
                  <a href="/terms" className="text-blue-600 hover:underline">
                    Terms of Service
                  </a>{" "}
                  and{" "}
                  <a href="/privacy" className="text-blue-600 hover:underline">
                    Privacy Policy
                  </a>
                </div>
              </div>
            )}
          </div>

          <DialogFooter className="flex space-x-2">
            <Button
              variant="outline"
              onClick={() => {
                setShowSignupDialog(false);
                setTempUserData(null);
                setError(null);
                window.location.href = "/login";
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleGoogleSignup(tempUserData)}
              disabled={loading}
              className="flex items-center space-x-2"
            >
              {loading ? (
                <>
                  <Loader2 className="h-4 w-4 animate-spin" />
                  <span>Creating Account...</span>
                </>
              ) : (
                <span>Create Account</span>
              )}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
