import React, { useState, useCallback, useEffect } from "react";
import {Link, Link as RouterLink} from "react-router-dom";
import {
  CheckCheck,
  ChevronDown,
  ChevronUp,
  CopyPlus,
  Loader2,
  Settings
} from "lucide-react";
import { cn } from "@/utils/utils.js";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import api from "@/config/axios.js";
import {getWorkspacePath} from "@/router/all.routers.jsx";

const INITIAL_FORM_STATE = {
  name: "",
  description: "",
};

export function WorkspaceSelector({ workspaces = [], onWorkspaceSelect }) {
  // State management
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Initialize selected workspace
  useEffect(() => {
    const selectedWorkspace = workspaces.find(
      (workspace) => workspace?.isCurrentlySelected
    );
    if (selectedWorkspace) {
      setSelectedWorkspaceId(selectedWorkspace.id);
    }
  }, [workspaces]);

  // Utility functions
  const makeAvatar = useCallback((name) => {
    return name
      .split(" ")
      .map((n) => n[0])
      .join("")
      .toUpperCase();
  }, []);

  const resetForm = useCallback(() => {
    setFormData(INITIAL_FORM_STATE);
    setErrors({});
    setIsSubmitting(false);
  }, []);

  // Form handling
  const handleInputChange = useCallback((e) => {
    const { id, value } = e.target;
    const field = id.split("-")[1];

    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));

    setErrors((prev) => ({
      ...prev,
      [field]: "",
    }));
  }, []);

  const validateForm = useCallback(() => {
    const newErrors = {};
    const { name, description } = formData;

    if (!name.trim()) newErrors.name = "Workspace name is required";
    if (!description.trim()) newErrors.description = "Workspace description is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData]);

  // API interactions
  const handleWorkspaceSelect = () => useCallback(async (workspaceId) => {
    const newId = workspaceId === selectedWorkspaceId ? "" : workspaceId;
    setSelectedWorkspaceId(newId);

    try {
      if (newId) {
        await api.post("/workspace/set-current-workspace", { workspaceId: newId });
      }
      onWorkspaceSelect?.(newId);
    } catch (error) {
      console.error("Failed to update workspace selection:", error);
    }
  }, [selectedWorkspaceId, onWorkspaceSelect]);

  const handleCreateWorkspace = async () => {
    if (!validateForm()) return;
    setIsSubmitting(true);

    try {
      const response = await api.post("/workspace/workspaces", formData);
      setIsModalOpen(false);
      resetForm();

      if (response?.id) {
        handleWorkspaceSelect(response.id);
      }
    } catch (error) {
      setErrors((prev) => ({
        ...prev,
        submit: error?.message || "Failed to create workspace",
      }));
    } finally {
      setIsSubmitting(false);
    }
  };

  // Render functions
  const renderCurrentWorkspace = () => {
    const currentWorkspace = workspaces.find(w => w.id === selectedWorkspaceId);
    if (!currentWorkspace) return null;

    return (
      <div className="p-3 bg-gray-300 rounded-md flex justify-between items-center">
        <Link to={getWorkspacePath('/:shortCode/dashboard', currentWorkspace?.shortCode)} className="flex items-center gap-3">
          <div className="inline-flex items-center justify-center size-8 rounded-full capitalize text-xs font-bold text-gray-100 bg-gray-600">
            {makeAvatar(currentWorkspace.name)}
          </div>
          <div className="flex-1">
            <h3 className="text-sm font-semibold text-gray-900 truncate">
              {currentWorkspace.name}
            </h3>
            <p className="text-xs text-gray-500">Current Workspace</p>
          </div>
        </Link>
        <div>
          <RouterLink
            to={getWorkspacePath('/:shortCode/workspace-settings', currentWorkspace?.shortCode)}
            className="flex items-center rounded-lg text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
          >
            <Settings size={22} />
          </RouterLink>

        </div>
      </div>
    );
  };

  const renderWorkspaceList = () => (
    <CommandList>
      <CommandEmpty>No workspace found.</CommandEmpty>
      <CommandGroup>
        {workspaces.map((workspace) => (
          <CommandItem
            key={workspace.id}
            value={workspace.id}
            onSelect={handleWorkspaceSelect}
            className={cn(
              "relative flex gap-x-2 rounded-lg p-2 hover:bg-white cursor-pointer mb-1",
              selectedWorkspaceId === workspace.id && "bg-gray-200"
            )}
          >
            <div className="inline-flex items-center justify-center size-8 rounded-full capitalize text-xs font-bold text-gray-900 bg-gray-300">
              {makeAvatar(workspace.name)}
            </div>
            <div className="flex-1">
              <h3 className="text-sm font-semibold text-gray-900 truncate">
                {workspace.name}
              </h3>
              {selectedWorkspaceId === workspace.id && (
                <p className="text-xs text-gray-500">Current Workspace</p>
              )}
            </div>
            {selectedWorkspaceId === workspace.id && <CheckCheck className="text-green-600" />}
          </CommandItem>
        ))}
      </CommandGroup>
    </CommandList>
  );

  const renderCreateWorkspaceModal = () => (
    <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Create New Workspace</DialogTitle>
          <DialogDescription>
            Add a new workspace to your account. Click save when you're done.
          </DialogDescription>
        </DialogHeader>

        <div className="flex flex-col gap-3 py-3">
          <div className="space-y-2">
            <label className="text-sm text-gray-700 font-medium">Name</label>
            <input
              type="text"
              id="workspace-name"
              value={formData.name}
              onChange={handleInputChange}
              className={cn(
                "w-full px-4 py-2 rounded-lg border hover:border-gray-500 transition-all",
                errors.name && "border-red-500"
              )}
              placeholder="My Workspace"
              disabled={isSubmitting}
            />
            {errors.name && <span className="text-red-500 text-sm">{errors.name}</span>}
          </div>

          <div className="space-y-2">
            <label className="text-sm text-gray-700 font-medium">Description</label>
            <textarea
              id="workspace-description"
              value={formData.description}
              onChange={handleInputChange}
              className={cn(
                "w-full px-4 py-2 rounded-lg border hover:border-gray-500 transition-all",
                errors.description && "border-red-500"
              )}
              placeholder="Workspace Description"
              disabled={isSubmitting}
            />
            {errors.description && <span className="text-red-500 text-sm">{errors.description}</span>}
          </div>
        </div>

        {errors.submit && (
          <div className="text-red-500 text-sm text-center">{errors.submit}</div>
        )}

        <DialogFooter>
          <Button
            onClick={handleCreateWorkspace}
            className="w-full bg-gray-600 hover:bg-gray-700"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <Loader2 className="w-6 h-6 animate-spin mx-auto" />
            ) : (
              "Create Workspace"
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );

  return (
    <Command className="rounded-lg bg-gray-100 m-3 p-2">
      {renderCurrentWorkspace()}

      <div className="border-t border-gray-200 my-2" />

      <div
        onClick={() => setIsExpanded(!isExpanded)}
        className="flex items-center px-3 py-2 rounded-sm cursor-pointer hover:bg-gray-50"
      >
        <h4 className="text-xs font-semibold text-gray-900 truncate">
          {isExpanded ? "Hide" : "Show"} Workspace
        </h4>
        <div className="flex items-center ml-auto">
          {!isExpanded && (
            <div className="flex -space-x-2">
              {workspaces.slice(0, 2).map((workspace) => (
                <div
                  key={workspace.id}
                  className="w-6 h-6 flex items-center justify-center text-[10px] font-semibold rounded-full bg-gray-400 border-2 border-white text-white"
                >
                  {workspace.name.charAt(0).toUpperCase()}
                </div>
              ))}
              {workspaces.length > 2 && (
                <div className="w-6 h-6 flex items-center justify-center text-[10px] font-semibold rounded-full bg-gray-500 border-2 border-white text-white">
                  +{workspaces.length - 2}
                </div>
              )}
            </div>
          )}
          {isExpanded ? (
            <ChevronUp size={20} strokeWidth="2.5" className="ml-2" />
          ) : (
            <ChevronDown size={20} strokeWidth="2.5" className="ml-2" />
          )}
        </div>
      </div>

      <div className="border-t border-gray-200 my-1" />

      {isExpanded && (
        <>
          <CommandInput placeholder="Search" className="h-9 px-3" />
          {renderWorkspaceList()}
          <div className="border-t border-gray-200 my-2" />
        </>
      )}

      <div
        className="cursor-pointer relative flex items-center gap-x-2 rounded-lg p-2 hover:bg-gray-50"
        onClick={() => setIsModalOpen(true)}
      >
        <div className="inline-flex items-center justify-center size-8 rounded-full text-sm font-semibold text-gray-900 bg-gray-300">
          <CopyPlus size={16} />
        </div>
        <div>
          <h3 className="text-sm font-semibold text-gray-900 truncate">
            Add workspace
          </h3>
        </div>
      </div>

      {renderCreateWorkspaceModal()}
    </Command>
  );
}

export default WorkspaceSelector;
