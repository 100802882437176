import React, { useRef } from 'react';
import { Card} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import {
  Download,
  Upload,
  FileJson,
  AlertCircle,
  History,
  Save,
  RotateCcw,
  Settings2
} from 'lucide-react';
import {Separator} from "@/components/ui/separator.jsx";

const getBackupsList = async () => {
  // In a real app, this would fetch from your backend
  return [
    { id: '1', date: '2025-01-05T10:00:00Z', size: '24KB' },
    { id: '2', date: '2025-01-04T10:00:00Z', size: '24KB' },
    { id: '3', date: '2025-01-03T10:00:00Z', size: '23KB' },
  ];
};

export const ImportExportSection = ({ settings, handleSettingChange, toast }) => {
  const [showImportDialog, setShowImportDialog] = React.useState(false);
  const [showRestoreDialog, setShowRestoreDialog] = React.useState(false);
  const [importedFile, setImportedFile] = React.useState(null);
  const [backups, setBackups] = React.useState([]);
  const [selectedBackup, setSelectedBackup] = React.useState(null);

  const fileInputRef = useRef(null);

  // Fetch backups on component mount
  React.useEffect(() => {
    const fetchBackups = async () => {
      const backupsList = await getBackupsList();
      setBackups(backupsList);
    };
    fetchBackups();
  }, []);

  const handleExportSettings = () => {
    try {
      const settingsStr = JSON.stringify(settings, null, 2);
      const blob = new Blob([settingsStr], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      const timestamp = new Date().toISOString().split('T')[0];

      a.href = url;
      a.download = `workspace-settings-${timestamp}.json`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

      toast({
        title: "Settings exported successfully",
        description: "Your settings have been downloaded as a JSON file.",
      });
    } catch (error) {
      toast({
        title: "Export failed",
        description: "There was an error exporting your settings.",
        variant: "destructive",
      });
    }
  };

  const validateSettingsFile = (fileContent) => {
    try {
      const parsed = JSON.parse(fileContent);
      // Add your validation logic here
      const requiredSections = ['general', 'detection', 'taskProperties', 'categorization', 'schedule', 'actionItems', 'meetingDefaults'];

      const missingKeys = requiredSections.filter(key => !parsed[key]);
      if (missingKeys.length > 0) {
        throw new Error(`Missing required sections: ${missingKeys.join(', ')}`);
      }

      return parsed;
    } catch (error) {
      throw new Error('Invalid settings file format');
    }
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImportedFile(file);
      setShowImportDialog(true);
    }
  };

  const handleImportConfirm = async () => {
    try {
      const fileContent = await importedFile.text();
      const validatedSettings = validateSettingsFile(fileContent);

      // Update settings
      Object.keys(validatedSettings).forEach(section => {
        handleSettingChange(section, null, validatedSettings[section]);
      });

      toast({
        title: "Settings imported successfully",
        description: "Your workspace settings have been updated.",
      });
    } catch (error) {
      toast({
        title: "Import failed",
        description: error.message,
        variant: "destructive",
      });
    } finally {
      setShowImportDialog(false);
      setImportedFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handleCreateBackup = async () => {
    try {
      // In a real app, this would call your backend API
      const timestamp = new Date().toISOString();
      const backupData = {
        settings,
        timestamp,
        metadata: {
          version: '1.0',
          creator: 'system'
        }
      };

      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1000));

      // Update backups list
      setBackups(prev => [{
        id: Date.now().toString(),
        date: timestamp,
        size: '24KB'
      }, ...prev]);

      toast({
        title: "Backup created successfully",
        description: "Your settings have been backed up.",
      });
    } catch (error) {
      toast({
        title: "Backup failed",
        description: "There was an error creating the backup.",
        variant: "destructive",
      });
    }
  };

  const handleRestoreBackup = async () => {
    try {
      // In a real app, this would fetch the backup data from your backend
      await new Promise(resolve => setTimeout(resolve, 1000));

      // Simulate restored settings
      const restoredSettings = settings; // In real app, this would be the fetched backup

      // Update settings
      Object.keys(restoredSettings).forEach(section => {
        handleSettingChange(section, null, restoredSettings[section]);
      });

      setShowRestoreDialog(false);
      setSelectedBackup(null);

      toast({
        title: "Settings restored successfully",
        description: "Your settings have been restored from the backup.",
      });
    } catch (error) {
      toast({
        title: "Restore failed",
        description: "There was an error restoring the backup.",
        variant: "destructive",
      });
    }
  };

  return (
    <Card className="border-none shadow-none">
        {/* Import/Export Group */}
        <div className="space-y-4">
          <div className="flex items-center gap-2">
            <Settings2 className="h-5 w-5 text-primary" />
            <h3 className="text-lg font-medium">Settings Transfer</h3>
          </div>
          <div className="pl-7">
            <div className="max-w-lg space-y-4">
              <div className="bg-muted/50 p-4 rounded-lg space-y-4">
                <div className="flex items-center justify-between">
                  <div>
                    <div className="text-sm font-medium">Export Settings</div>
                    <div className="text-sm text-muted-foreground mt-1">
                      Download your current workspace configuration
                    </div>
                  </div>
                  <Button
                    onClick={handleExportSettings}
                    variant="outline"
                    className="flex items-center gap-2"
                  >
                    <Download className="h-4 w-4" />
                    Export
                  </Button>
                </div>
              </div>

              <div className="bg-muted/50 p-4 rounded-lg space-y-4">
                <div className="flex items-center justify-between">
                  <div>
                    <div className="text-sm font-medium">Import Settings</div>
                    <div className="text-sm text-muted-foreground mt-1">
                      Upload a workspace settings file
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <Button
                      onClick={() => fileInputRef.current?.click()}
                      variant="outline"
                      className="flex items-center gap-2"
                    >
                      <Upload className="h-4 w-4" />
                      Import
                    </Button>
                    <input
                      type="file"
                      ref={fileInputRef}
                      className="hidden"
                      accept=".json"
                      onChange={handleFileSelect}
                    />
                  </div>
                </div>
                <div className="flex items-center gap-2 text-sm text-muted-foreground">
                  <FileJson className="h-4 w-4" />
                  Only .json files are supported
                </div>
              </div>
            </div>
          </div>
        </div>

        <Separator />

        {/* Backup Group */}
        <div className="space-y-4">
          <div className="flex items-center gap-2">
            <History className="h-5 w-5 text-primary" />
            <h3 className="text-lg font-medium">Backups</h3>
          </div>
          <div className="pl-7">
            <div className="max-w-lg space-y-4">
              <div className="bg-muted/50 p-4 rounded-lg space-y-4">
                <div className="flex items-center justify-between">
                  <div>
                    <div className="text-sm font-medium">Create Backup</div>
                    <div className="text-sm text-muted-foreground mt-1">
                      Save a snapshot of current settings
                    </div>
                  </div>
                  <Button
                    onClick={handleCreateBackup}
                    variant="outline"
                    className="flex items-center gap-2"
                  >
                    <Save className="h-4 w-4" />
                    Backup
                  </Button>
                </div>
              </div>

              <div className="bg-muted/50 p-4 rounded-lg space-y-4">
                <div>
                  <div className="text-sm font-medium">Restore Backup</div>
                  <div className="text-sm text-muted-foreground mt-1">
                    Restore settings from a previous backup
                  </div>
                </div>
                <Select
                  value={selectedBackup}
                  onValueChange={setSelectedBackup}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select a backup to restore" />
                  </SelectTrigger>
                  <SelectContent>
                    {backups.map((backup) => (
                      <SelectItem key={backup.id} value={backup.id}>
                        {new Date(backup.date).toLocaleString()} ({backup.size})
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <Button
                  onClick={() => setShowRestoreDialog(true)}
                  disabled={!selectedBackup}
                  variant="outline"
                  className="w-full flex items-center gap-2 justify-center"
                >
                  <RotateCcw className="h-4 w-4" />
                  Restore Selected Backup
                </Button>
              </div>
            </div>
          </div>
        </div>

        {/* Warning Message */}
        <div className="pl-7 max-w-lg">
          <div className="p-4 bg-yellow-50 rounded-lg border border-yellow-200">
            <div className="flex items-start gap-3">
              <AlertCircle className="h-5 w-5 text-yellow-600 mt-0.5" />
              <div>
                <h4 className="text-sm font-medium text-yellow-800">Important Note</h4>
                <p className="text-sm text-yellow-700 mt-1">
                  Importing settings or restoring from a backup will override your current configuration.
                  Make sure to export or backup your current settings first.
                </p>
              </div>
            </div>
          </div>
        </div>
      <AlertDialog open={showImportDialog} onOpenChange={setShowImportDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Import Settings</AlertDialogTitle>
            <AlertDialogDescription>
              This will override your current workspace settings. Are you sure you want to continue?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => {
              setShowImportDialog(false);
              setImportedFile(null);
              if (fileInputRef.current) {
                fileInputRef.current.value = '';
              }
            }}>
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction onClick={handleImportConfirm}>
              Import
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <AlertDialog open={showRestoreDialog} onOpenChange={setShowRestoreDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Restore Backup</AlertDialogTitle>
            <AlertDialogDescription>
              This will restore your settings to the state saved in the backup from{' '}
              {selectedBackup && backups.find(b => b.id === selectedBackup)?.date ?
                new Date(backups.find(b => b.id === selectedBackup).date).toLocaleString() :
                'the selected date'
              }. Are you sure you want to continue?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => {
              setShowRestoreDialog(false);
              setSelectedBackup(null);
            }}>
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction onClick={handleRestoreBackup}>
              Restore
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Card>
  );
};
