import { Navigate, useLocation, useParams } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import LoadingSpinner from "@/components/common/spinner.jsx";
import { useProfileStore } from "@/store/slices/profileSlice.js";

const ProtectedRoute = ({ children, requiredRole }) => {
  const { isAuthenticated, isLoading, user } = useAuth();
  const { workspaces } = useProfileStore();
  const location = useLocation();
  const { shortCode } = useParams();
  const currentPath = location.pathname;

  const ignoreShortCodePaths = [
    "/onboarding/welcome",
    "/onboarding/profile",
    "/onboarding/preferences",
    "/all-workspaces",
    "/billings",
    "/integrations/google/callback",
    "/settings",
    "/billings",
    "/teams",
    "/security",
    "/notifications",
  ];

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Only check onboarding status for non-onboarding routes
  if (
    !currentPath.startsWith("/onboarding") &&
    user &&
    !user.isOnboardingDone
  ) {
    return <Navigate to="/onboarding/welcome" replace />;
  }

  if (requiredRole && user?.role !== requiredRole) {
    return <Navigate to="/unauthorized" replace />;
  }

  if (
    workspaces?.find((w) => w.shortCode !== shortCode) &&
    !ignoreShortCodePaths.includes(currentPath)
  ) {
    return <Navigate to="/unauthorized" replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
