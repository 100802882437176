import { Building, Image, FileText } from 'lucide-react';
import {Card, CardContent} from "@/components/ui/card.jsx";
import {Avatar, AvatarFallback, AvatarImage} from "@/components/ui/avatar.jsx";
import {Input} from "@/components/ui/input.jsx";
import {Textarea} from "@/components/ui/textarea.jsx";

export const WorkspaceDetailsSection = ({
                                          settings,
                                          handleSettingChange,
                                          handleImageChange,
                                          handleTextFieldBlur
                                        }) => {
  if (settings && Object.keys(settings).length === 0) {
    return null;
  }

  return (
    <Card className="border-none shadow-none">

      <CardContent className="flex justify-between gap-6">
        {/* Workspace Identity */}
        <div className="w-full space-y-4 ">
          <div className="flex items-center gap-2">
            <Building className="h-5 w-5 text-primary" />
            <h3 className="text-lg font-medium">Workspace Identity</h3>
          </div>
          <div className="pl-7">
            <div className="grid gap-6 max-w-lg">
              <div className=" p-4 rounded-lg space-y-4">
                <div className="flex items-center gap-6">
                  <div className="relative">
                    <Avatar className="h-16 w-16">
                      <AvatarImage src="https://github.com/shadcn.png" alt="Workspace Logo" />
                      <AvatarFallback>WS</AvatarFallback>
                    </Avatar>
                    <div className="absolute -bottom-2 -right-2">
                      <div className="relative">
                        <input
                          type="file"
                          id="workspace-logo"
                          accept="image/*"
                          className="hidden"
                          onChange={handleImageChange}
                        />
                        <label htmlFor="workspace-logo">
                          <div className="h-7 w-7 rounded-full bg-primary flex items-center justify-center cursor-pointer hover:bg-primary/90">
                            <Image className="h-4 w-4 text-white" />
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="text-sm font-medium">Workspace Logo</div>
                    <div className="text-sm text-muted-foreground mt-1">
                      Upload a logo for your workspace
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-4 rounded-lg space-y-3">
                <div className="text-sm font-medium">Workspace Name</div>
                <div className="text-sm text-muted-foreground">
                  The name will be visible to all members
                </div>
                <Input
                  placeholder="Enter workspace name"
                  className="w-full bg-white"
                  value={settings.general.workspaceName}
                  onChange={(e) => handleSettingChange('general', 'workspaceName', e.target.value, true)}
                  onBlur={() => handleTextFieldBlur('general', 'workspaceName')}
                />
              </div>
            </div>
          </div>
        </div>
        {/*<Separator />*/}
        {/* Workspace Description */}
        <div className="w-full space-y-4">
          <div className="flex items-center gap-2">
            <FileText className="h-5 w-5 text-primary" />
            <h3 className="text-lg font-medium">Description</h3>
          </div>
          <div className="pl-7">
            <div className="grid gap-4 max-w-lg">
              <div className="p-4 rounded-lg space-y-3">
                <div className="text-sm font-medium">Workspace Description</div>
                <div className="text-sm text-muted-foreground">
                  Help members understand the purpose of this workspace
                </div>
                <Textarea
                  placeholder="Enter description"
                  className="w-full bg-white h-full"
                  value={settings.general.description}
                  onChange={(e) => handleSettingChange('general', 'description', e.target.value, true)}
                  onBlur={() => handleTextFieldBlur('general', 'description')}
                />
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
