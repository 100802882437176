import React from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardDescription,
} from "@/components/ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import { Separator } from "@/components/ui/separator";
import {
  Clock,
  Video,
  FileText,
  Users,
  Share2,
  MessageSquare,
  Videotape,
  Captions,
} from "lucide-react";

export const MeetingDefaultsSection = ({ settings, handleSettingChange }) => (
  <Card className="border-none shadow-none">
    <CardContent className="space-y-8">
      <div className={`flex justify-between gap-6`}>
        {/* Duration Settings */}
        <div className="w-full space-y-4">
          <div className="flex items-center gap-2">
            <Clock className="h-5 w-5 text-primary" />
            <h3 className="text-lg font-medium">Duration</h3>
          </div>
          <div className="pl-7">
            <div className="max-w-lg">
              <div className="p-4 rounded-lg space-y-4">
                <div>
                  <div className="text-sm font-medium">
                    Default Meeting Length
                  </div>
                  <div className="text-sm text-muted-foreground mt-1">
                    Set the standard duration for new meetings
                  </div>
                </div>
                <Select
                  value={settings.meetingDefaults.duration}
                  onValueChange={(value) =>
                    handleSettingChange("meetingDefaults", "duration", value)
                  }
                >
                  <SelectTrigger className="w-56">
                    <SelectValue placeholder="Select duration" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="15">15 Minutes (Quick Sync)</SelectItem>
                    <SelectItem value="30">30 Minutes (Standard)</SelectItem>
                    <SelectItem value="45">45 Minutes (Extended)</SelectItem>
                    <SelectItem value="60">
                      60 Minutes (Long Meeting)
                    </SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>
        </div>

        {/* Notes Settings */}
        <div className="w-full space-y-4">
          <div className="flex items-center gap-2">
            <FileText className="h-5 w-5 text-primary" />
            <h3 className="text-lg font-medium">Notes & Summary</h3>
          </div>
          <div className="pl-7">
            <div className="max-w-lg">
              <div className="p-4 rounded-lg space-y-4">
                <div>
                  <div className="text-sm font-medium">Notes Template</div>
                  <div className="text-sm text-muted-foreground mt-1">
                    Choose how meeting notes are structured
                  </div>
                </div>
                <Select
                  value={settings.meetingDefaults.notesTemplate}
                  onValueChange={(value) =>
                    handleSettingChange(
                      "meetingDefaults",
                      "notesTemplate",
                      value,
                    )
                  }
                >
                  <SelectTrigger className="w-48">
                    <SelectValue placeholder="Select template" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="basic">Basic Template</SelectItem>
                    <SelectItem value="detailed">Detailed Template</SelectItem>
                    <SelectItem value="custom">Custom Template</SelectItem>
                    <SelectItem value="none">No Template</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Separator />

      {/* Recording Settings */}
      <div className="space-y-4">
        <div className="flex items-center gap-2">
          <Video className="h-5 w-5 text-primary" />
          <h3 className="text-lg font-medium">Recording</h3>
        </div>
        <div className="pl-7">
          <div className="w-full">
            <div className="p-4 rounded-lg space-y-6">
              <div className="grid grid-cols-2 gap-6">
                <div className="bg-muted/50 p-4 rounded-lg space-y-4 flex items-center gap-3 ">
                  <Videotape className="h-5 w-5 text-primary" />
                  <div className="flex-1">
                    <div className="text-sm font-medium">Auto Record</div>
                    <div className="text-xs text-muted-foreground mt-1">
                      Record meetings automatically
                    </div>
                  </div>
                  <Switch
                    checked={settings.meetingDefaults.autoRecord}
                    onCheckedChange={(checked) =>
                      handleSettingChange(
                        "meetingDefaults",
                        "autoRecord",
                        checked,
                      )
                    }
                  />
                </div>
                <div className="bg-muted/50 p-4 rounded-lg space-y-4 flex items-center gap-3 ">
                  <Captions className="h-5 w-5 text-primary" />
                  <div className="flex-1 !my-0">
                    <div className="text-sm font-medium">Transcription</div>
                    <div className="text-xs text-muted-foreground mt-1">
                      Generate meeting transcript
                    </div>
                  </div>
                  <Switch
                    className={`!my-0`}
                    checked={settings.meetingDefaults.transcription}
                    onCheckedChange={(checked) =>
                      handleSettingChange(
                        "meetingDefaults",
                        "transcription",
                        checked,
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Separator />

      {/* Participant Settings */}
      <div className="space-y-4">
        <div className="flex items-center gap-2">
          <Users className="h-5 w-5 text-primary" />
          <h3 className="text-lg font-medium">Participant Controls</h3>
        </div>
        <div className="pl-7">
          <div className="max-w-lg">
            <div className="p-4 rounded-lg space-y-6">
              <div className="grid grid-cols-2 gap-4">
                <div className="flex items-center gap-3 bg-background/60 p-3 rounded-md">
                  <div className="flex-1">
                    <div className="flex items-center gap-2">
                      <Share2 className="h-4 w-4 text-muted-foreground" />
                      <span className="text-sm font-medium">
                        Screen Sharing
                      </span>
                    </div>
                  </div>
                  <Switch
                    checked={
                      settings.meetingDefaults.participantControls.screenShare
                    }
                    onCheckedChange={(checked) =>
                      handleSettingChange(
                        "meetingDefaults",
                        "participantControls.screenShare",
                        checked,
                      )
                    }
                  />
                </div>
                <div className="flex items-center gap-3 bg-background/60 p-3 rounded-md">
                  <div className="flex-1">
                    <div className="flex items-center gap-2">
                      <MessageSquare className="h-4 w-4 text-muted-foreground" />
                      <span className="text-sm font-medium">Chat Access</span>
                    </div>
                  </div>
                  <Switch
                    checked={settings.meetingDefaults.participantControls.chat}
                    onCheckedChange={(checked) =>
                      handleSettingChange(
                        "meetingDefaults",
                        "participantControls.chat",
                        checked,
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CardContent>
  </Card>
);
