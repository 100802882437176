import {Tags, GitMerge, Settings2, Sparkles, Brain, Workflow} from 'lucide-react';
import {Card, CardDescription, CardTitle} from "@/components/common/cards/Card.jsx";
import {CardContent, CardHeader} from "@/components/ui/card.jsx";
import {Switch} from "@/components/ui/switch.jsx";
import {Separator} from "@/components/ui/separator.jsx";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "@/components/ui/select.jsx";

export const AutoCategorizationSection = ({ settings, handleSettingChange }) => (
  <Card className="border-none shadow-none">

    <CardContent className="space-y-8">
      {/* Smart Features */}
      <div className="space-y-4">
        <div className="flex items-center gap-2">
          <Sparkles className="h-5 w-5 text-primary" />
          <h3 className="text-lg font-medium">Smart Features</h3>
        </div>
        <div className="pl-7">
          <div className="grid grid-cols-2 gap-4">
            <div className="bg-muted/50 p-4 rounded-lg">
              <div className="flex items-center gap-3 mb-2">
                <Tags className="h-5 w-5 text-primary" />
                <div className={`flex-1`}>
                  <div className="text-sm font-medium">Smart Labels</div>
                  <div className="text-sm text-muted-foreground mt-1">
                    AI-powered label suggestions
                  </div>
                </div>
                <Switch
                  checked={settings.categorization.smartLabels}
                  onCheckedChange={(checked) =>
                    handleSettingChange('categorization', 'smartLabels', checked)
                  }
                />
              </div>
            </div>
            <div className="bg-muted/50 p-4 rounded-lg">
              <div className="flex items-center  mb-2 gap-3">
                <Brain className="h-5 w-5 text-primary" />
                <div className={`flex-1`}>
                  <div className="text-sm font-medium">Project Detection</div>
                  <div className="text-sm text-muted-foreground mt-1">
                    Auto-assign to projects
                  </div>
                </div>
                <Switch
                  checked={settings.categorization.projectDetection}
                  onCheckedChange={(checked) =>
                    handleSettingChange('categorization', 'projectDetection', checked)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Separator />

      {/* Tagging Rules */}
      <div className="space-y-4">
        <div className="flex items-center gap-2">
          <Tags className="h-5 w-5 text-primary" />
          <h3 className="text-lg font-medium">Tagging Rules</h3>
        </div>
        <div className="pl-7">
          <div className="max-w-lg">
            <div className="bg-muted/50 p-4 rounded-lg space-y-4">
              <div className="flex items-center gap-3">
                <Workflow className="h-5 w-5 text-primary" />
                <div className={`flex-1`}>
                  <div className="text-sm font-medium">Automatic Tagging</div>
                  <div className="text-sm text-muted-foreground mt-1">
                    Configure rules for applying tags
                  </div>
                </div>
                <Switch
                  checked={settings.categorization.tagRules}
                  onCheckedChange={(checked) =>
                    handleSettingChange('categorization', 'tagRules', checked)
                  }
                />
              </div>
              {settings.categorization.tagRules && (
                <div className="pt-2">
                  <Select
                    value={settings.categorization.tagRulesBehavior}
                    onValueChange={(value) =>
                      handleSettingChange('categorization', 'tagRulesBehavior', value)
                    }
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Select tagging behavior"/>
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="suggest">Suggest Tags</SelectItem>
                      <SelectItem value="autoApply">Auto-apply Tags</SelectItem>
                      <SelectItem value="requireApproval">Require Approval</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </CardContent>
  </Card>
);
