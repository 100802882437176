import React, { useState, useEffect, useRef } from 'react';
import {
    X, Check, AlertCircle, Calendar,
    MessageSquare, Users, CheckCircle, Inbox
} from 'lucide-react';

const generateDummyNotifications = (start = 0, count = 10) => {
    const types = [
        {
            type: 'comment',
            icon: MessageSquare,
            title: 'New comment on "Project Timeline"',
            iconBg: 'bg-blue-500',
            iconColor: 'text-white'
        },
        {
            type: 'meeting',
            icon: Calendar,
            title: 'Upcoming Meeting Reminder',
            iconBg: 'bg-purple-100',
            iconColor: 'text-purple-600'
        },
        {
            type: 'task',
            icon: CheckCircle,
            title: 'Task Completed',
            iconBg: 'bg-green-100',
            iconColor: 'text-green-600'
        },
        {
            type: 'assignment',
            icon: Users,
            title: 'New Task Assignment',
            iconBg: 'bg-orange-100',
            iconColor: 'text-orange-600'
        }
    ];
    return Array.from({ length: count }, (_, i) => {
        const typeIndex = Math.floor(Math.random() * types.length);
        const typeInfo = types[typeIndex];
        return {
            id: start + i + 1,
            ...typeInfo,
            message: `This is a sample ${typeInfo.type} notification message ${start + i + 1}`,
            time: `${Math.floor(Math.random() * 24)} hours ago`,
            unread: Math.random() > 0.7,
            action: Math.random() > 0.5 ? 'View' : null,
            group: i < 3 ? 'today' : 'earlier'
        };
    });
};

const NotificationCenter = ({ onClose }) => {
    const [notifications, setNotifications] = useState([]);
    const [filter, setFilter] = useState('all');
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(0);
    const [showSettings, setShowSettings] = useState(false);
    const containerRef = useRef(null);
    const notificationRef = useRef(null);

    // Initial load and preload
    useEffect(() => {
        const loadInitialBatch = () => {
            const initialNotifications = generateDummyNotifications(0, 10);
            setNotifications(initialNotifications);
            // Preload next batch
            setTimeout(() => {
                const nextBatch = generateDummyNotifications(10, 10);
                setNotifications(prev => [...prev, ...nextBatch]);
                setPage(2);
            }, 100);
        };

        loadInitialBatch();
    }, []);

    // Handle outside click
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (notificationRef.current && !notificationRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [onClose]);

    // Handle scroll pagination with preloading
    const handleScroll = (e) => {
        const { scrollTop, clientHeight, scrollHeight } = e.target;
        if (scrollHeight - scrollTop <= clientHeight * 1.5 && !loading && hasMore) {
            setLoading(true);
            const newNotifications = generateDummyNotifications(page * 10, 10);
            setNotifications(prev => [...prev, ...newNotifications]);
            setPage(prev => prev + 1);
            setLoading(false);
            if (page >= 5) setHasMore(false);

            // Preload next batch
            if (page < 5) {
                const nextBatch = generateDummyNotifications((page + 1) * 10, 10);
                setTimeout(() => {
                    setNotifications(prev => [...prev, ...nextBatch]);
                }, 100);
            }
        }
    };

    const filteredNotifications = notifications.filter(notification => {
        if (filter === 'unread') return notification.unread;
        return true;
    });

    const groupedNotifications = {
        today: filteredNotifications.filter(n => n.group === 'today'),
        earlier: filteredNotifications.filter(n => n.group === 'earlier')
    };

    const handleMarkAllRead = () => {
        setNotifications(notifications.map(n => ({ ...n, unread: false })));
    };

    const handleActionClick = (e, action) => {
        e.stopPropagation();
        console.log('Action clicked:', action);
    };

    return (
      <div ref={notificationRef} className="fixed inset-y-0 right-0 w-96 bg-white border-l shadow-lg z-40">
          <div className="p-4 border-b">
              <div className="flex items-center justify-between mb-4">
                  <h2 className="text-lg font-semibold">Notifications</h2>
                  <div className="flex items-center space-x-2">
                      <button
                        className="p-2 text-gray-400 hover:text-gray-600 rounded"
                        onClick={onClose}
                      >
                          <X className="h-5 w-5" />
                      </button>
                  </div>
              </div>

              {!showSettings && (
                <div className="flex items-center space-x-2">
                    {['all', 'unread'].map((filterType) => (
                      <button
                        key={filterType}
                        className={`px-3 py-1.5 rounded-lg text-sm ${
                          filter === filterType
                            ? 'bg-blue-50 text-blue-600'
                            : 'text-gray-600 hover:bg-gray-100'
                        }`}
                        onClick={() => setFilter(filterType)}
                      >
                          {filterType.charAt(0).toUpperCase() + filterType.slice(1)}
                      </button>
                    ))}
                </div>
              )}
          </div>
          <>
              <div
                className="overflow-y-auto h-[calc(100vh-100px)]"
                onScroll={handleScroll}
                ref={containerRef}
              >
                  {Object.entries(groupedNotifications).every(([_, items]) => items.length === 0) ? (
                    <div className="flex flex-col items-center justify-center h-full text-gray-500">
                        <Inbox className="h-12 w-12 mb-2" />
                        <p className="text-lg font-medium">No notifications yet</p>
                        <p className="text-sm">We'll notify you when something arrives</p>
                    </div>
                  ) : (
                    Object.entries(groupedNotifications).map(([group, items]) => items.length > 0 && (
                      <div key={group} className="py-4">
                          <h3 className="px-4 text-sm font-medium text-gray-500 mb-2">
                              {group.charAt(0).toUpperCase() + group.slice(1)}
                          </h3>
                          <div className="space-y-1">
                              {items.map((notification) => {
                                  const Icon = notification.icon;
                                  return (
                                    <div
                                      key={notification.id}
                                      className={`px-4 py-3 cursor-pointer ${
                                        notification.unread
                                          ? 'bg-blue-50 hover:bg-blue-100'
                                          : 'hover:bg-gray-50'
                                      }`}
                                    >
                                        <div className="flex items-start">
                                            <div className="flex-shrink-0 mt-1">
                                                <div className={`h-8 w-8 ${notification.iconBg} rounded-full flex items-center justify-center`}>
                                                    <Icon className={`h-4 w-4 ${notification.iconColor}`} />
                                                </div>
                                            </div>
                                            <div className="ml-3 flex-1">
                                                <p className="text-sm font-medium">{notification.title}</p>
                                                <p className="text-sm text-gray-600">{notification.message}</p>
                                                <div className="flex items-center mt-2 space-x-4">
                                                    <span className="text-xs text-gray-500">{notification.time}</span>
                                                    {notification.action && (
                                                      <button
                                                        className="text-xs text-blue-600 hover:text-blue-700"
                                                        onClick={(e) => handleActionClick(e, notification.action)}
                                                      >
                                                          {notification.action}
                                                      </button>
                                                    )}
                                                </div>
                                            </div>
                                            {notification.unread && (
                                              <div className="ml-3 flex-shrink-0">
                                                  <div className="h-2 w-2 bg-blue-600 rounded-full"></div>
                                              </div>
                                            )}
                                        </div>
                                    </div>
                                  );
                              })}
                          </div>
                      </div>
                    ))
                  )}
              </div>

              <div className="absolute bottom-0 left-0 right-0 border-t bg-white p-4">
                  <div className="flex items-center justify-between">
                      <button
                        className="text-sm text-gray-600 hover:text-gray-800"
                        onClick={handleMarkAllRead}
                      >
                          Mark all as read
                      </button>
                  </div>
              </div>
          </>
      </div>
    );
};

const ToastNotification = ({ type, message, action, onClose, displayTime = 3000 }) => {
    const [progress, setProgress] = useState(0);
    const progressInterval = useRef(null);
    const startTimeRef = useRef(Date.now());
    const pausedTimeRef = useRef(0);

    const types = {
        success: {
            icon: Check,
            className: 'bg-green-50 border-green-200',
            iconClass: 'text-green-500',
            textClass: 'text-green-800',
            progressClass: 'bg-green-500'
        },
        error: {
            icon: AlertCircle,
            className: 'bg-red-50 border-red-200',
            iconClass: 'text-red-500',
            textClass: 'text-red-800',
            progressClass: 'bg-red-500'
        },
        info: {
            icon: AlertCircle,
            className: 'bg-blue-50 border-blue-200',
            iconClass: 'text-blue-500',
            textClass: 'text-blue-800',
            progressClass: 'bg-blue-500'
        }
    };

    const { icon: Icon, className, iconClass, textClass, progressClass } = types[type];

    const startTimer = () => {
        if (progressInterval.current) clearInterval(progressInterval.current);
        const startTime = Date.now();
        startTimeRef.current = startTime;

        progressInterval.current = setInterval(() => {
            const elapsedTime = Date.now() - startTime + pausedTimeRef.current;
            const newProgress = Math.min((elapsedTime / displayTime) * 100, 100);
            setProgress(newProgress);

            if (newProgress >= 100) {
                clearInterval(progressInterval.current);
                handleClose();
            }
        }, 16);
    };

    const handleMouseEnter = () => {
        clearInterval(progressInterval.current);
        pausedTimeRef.current = Date.now() - startTimeRef.current + pausedTimeRef.current;
    };

    const handleMouseLeave = () => {
        startTimer();
    };

    const handleClose = () => {
        clearInterval(progressInterval.current);
        if (onClose) onClose();
    };

    useEffect(() => {
        startTimer();
        return () => clearInterval(progressInterval.current);
    }, [displayTime]);

    return (
      <div
        className={`fixed top-1 right-1 max-w-sm w-full border shadow-lg ${className}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
          <div className="p-4">
              <div className="flex items-start">
                  <div className="flex-shrink-0">
                      <Icon className={`h-5 w-5 ${iconClass}`} />
                  </div>
                  <div className="ml-3 w-0 flex-1">
                      <p className={`text-sm font-medium ${textClass}`}>{message}</p>
                      {action && (
                        <div className="mt-2">
                            <button className="text-sm font-medium text-blue-600 hover:text-blue-500">
                                {action}
                            </button>
                        </div>
                      )}
                  </div>
                  <div className="ml-4 flex-shrink-0">
                      <button
                        className="inline-flex text-gray-400 hover:text-gray-500"
                        onClick={handleClose}
                      >
                          <X className="h-5 w-5" />
                      </button>
                  </div>
              </div>
          </div>
          <div className="relative h-1 w-full bg-gray-200 overflow-hidden">
              <div
                className={`absolute h-full transition-all duration-75 ease-linear ${progressClass}`}
                style={{ width: `${progress}%` }}
              />
          </div>
      </div>
    );
};

export { NotificationCenter, ToastNotification };
