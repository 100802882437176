import React from "react";
import {
  Calendar,
  CheckSquare,
  HelpCircle,
  MessageSquare,
  Settings,
  Zap,
  Link,
  LayoutDashboard,
  ChartNoAxesCombined,
  ClipboardList,
  CalendarDays,
  Ticket,
  ServerCog,
  ChartPie,
  Video,
  Atom,
} from "lucide-react";
import GoogleCallback from "@/pages/public/GoogleCallBack.jsx";
import ConnectCallback from "@/pages/integration/integration.callback.jsx";
import MeetingSettings from "@/pages/settings/meetings.jsx";
import TaskBoardView from "@/pages/tasks/board-view.jsx";
import WorkspaceSettings from "@/pages/workspace/WorkspaceSettings.jsx";
import AutomationHistory from "@/pages/automation/history.jsx";
import { Navigate } from "react-router-dom";
import { useProfileStore } from "@/store/slices/profileSlice.js";
import WorkspaceRoute from "@/router/WorkspaceRoute.jsx";
import WorkspaceUI from "@/pages/dashboard/allWorkspace.jsx";

// Lazy load page components
const Dashboard = React.lazy(() => import("../pages/dashboard"));
const Tasks = React.lazy(() => import("@/pages/tasks"));
// const TasksCalender = React.lazy(() => import("@/pages/tasks/calendar-view"));
const Meetings = React.lazy(() => import("@/pages/meetings"));
// const Analytics = React.lazy(() => import("@/pages/analytics"));
const ProfileSettings = React.lazy(() => import("@/pages/settings/profile"));
const NotificationSettings = React.lazy(
  () => import("@/pages/settings/notifications"),
);
const SecuritySettings = React.lazy(() => import("@/pages/settings/security"));
const TeamSettings = React.lazy(() => import("@/pages/settings/team"));
// const LanguageSettings = React.lazy(() => import("@/pages/settings/language-region"));
// const AppearanceSettings = React.lazy(() => import("@/pages/settings/appearance"));
// const EmailSettings = React.lazy(() => import("@/pages/settings/email"));
const BillingSettings = React.lazy(() => import("@/pages/settings/billing"));
const Help = React.lazy(() => import("@/pages/help"));
const AutomationPage = React.lazy(() => import("@/pages/automation"));
const AutomationBuilder = React.lazy(
  () => import("@/pages/automation/builder"),
);
const Integrations = React.lazy(
  () => import("@/pages/integration/integrations"),
);
const LandingPage = React.lazy(() => import("@/pages/public/landing"));
const AuthPage = React.lazy(() => import("@/pages/auth/LoginPage"));
const VerifyEmailPage = React.lazy(
  () => import("@/pages/auth/verifyEmailPage"),
);
const ForgotPasswordPage = React.lazy(
  () => import("@/pages/auth/forgotPasswordPage"),
);
const ResetPasswordPage = React.lazy(() => import("@/pages/resetPasswordPage"));
const TaskCalendarView = React.lazy(() => import("@/pages/calendar"));
const OnboardingWelcome = React.lazy(() => import("@/pages/onboarding/index"));
const RegistrationWizard = React.lazy(
  () => import("@/pages/auth/RegistrationPage"),
);
const FeaturesPage = React.lazy(() => import("@/pages/public/FeaturePage"));
const PricingPage = React.lazy(() => import("@/pages/public/PricingPage"));
const OnboardingFlow = React.lazy(
  () => import("@/pages/onboarding/Onboarding"),
);
const Unauthorized = React.lazy(() => import("@/pages/auth/unauthorized"));

export const getWorkspacePath = (basePath, shortCode) => {
  if (basePath === "/all-workspaces") return basePath;
  return basePath.replace(":shortCode", shortCode || "");
};

const prefix = "/:shortCode";
export const sidebarRoutes = [
  {
    path: `${prefix}/dashboard`,
    icon: LayoutDashboard,
    label: "Dashboard",
    component: (props) => <WorkspaceRoute component={Dashboard} {...props} />,
    location: "sideBar",
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: `${prefix}/calendar`,
    icon: CalendarDays,
    label: "calendar",
    component: (props) => (
      <WorkspaceRoute component={TaskCalendarView} {...props} />
    ),
    location: "sideBar",
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: `${prefix}/tasks`,
    icon: ClipboardList,
    label: "Tasks",
    component: (props) => <WorkspaceRoute component={Tasks} {...props} />,
    location: "sideBar",
    layout: "mainLayout",
    isProtected: true,
    children: [
      {
        path: `${prefix}/tasks/subtask1`,
        label: "Subtask 1",
        component: (props) => <WorkspaceRoute component={Tasks} {...props} />, // Replace with your component
        location: "sideBar",
        layout: "mainLayout",
        isProtected: true,
      },
      {
        path: `${prefix}/tasks/subtask2`,
        label: "Subtask 2",
        component: (props) => <WorkspaceRoute component={Tasks} {...props} />, // Replace with your component
        location: "sideBar",
        layout: "mainLayout",
        isProtected: true,
      },
    ],
  },
  {
    path: `${prefix}/meetings`,
    icon: Video,
    label: "Meetings",
    component: (props) => <WorkspaceRoute component={Meetings} {...props} />,
    location: "sideBar",
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: `${prefix}/integrations`,
    icon: Link,
    label: "integrations",
    component: (props) => (
      <WorkspaceRoute component={Integrations} {...props} />
    ),
    location: "sideBar",
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: `${prefix}/automation`,
    icon: Atom,
    label: "automation",
    component: (props) => (
      <WorkspaceRoute component={AutomationPage} {...props} />
    ),
    location: "sideBar",
    layout: "mainLayout",
    isProtected: true,
  },
  // {
  //   path: `${prefix}/analytics`,
  //   icon: ChartPie,
  //   label: "Analytics",
  //   component: (props) => <WorkspaceRoute component={Analytics} {...props} />,
  //   location: "sideBar",
  //   layout: "mainLayout",
  //   isProtected: true,
  // },
];

export const protectedRRoutes = [
  {
    path: "/all-workspaces",
    icon: LayoutDashboard,
    label: "Dashboard",
    component: WorkspaceUI,
    location: "sideBar",
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: `${prefix}/tasks/board-view`,
    icon: CheckSquare,
    label: "Tasks",
    component: (props) => (
      <WorkspaceRoute component={TaskBoardView} {...props} />
    ),
    location: "sideBar",
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: `${prefix}/create/automation`,
    icon: CheckSquare,
    label: "Tasks",
    component: (props) => (
      <WorkspaceRoute component={AutomationBuilder} {...props} />
    ),
    location: "sideBar",
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: `${prefix}/automation/:id/history`,
    icon: CheckSquare,
    label: "Tasks",
    component: (props) => (
      <WorkspaceRoute component={AutomationHistory} {...props} />
    ),
    location: "sideBar",
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: `/settings`,
    icon: Settings,
    label: "Settings",
    component: (props) => (
      <WorkspaceRoute component={WorkspaceSettings} {...props} />
    ),
    location: null,
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: `${prefix}/workspace-settings`,
    icon: Settings,
    label: "Settings",
    component: (props) => (
      <WorkspaceRoute component={WorkspaceSettings} {...props} />
    ),
    location: null,
    layout: "mainLayout",
    isProtected: true,
  },
  // {
  //   path: `${prefix}/tasks/calendar-view`,
  //   icon: CheckSquare,
  //   label: "Tasks",
  //   component: (props) => <WorkspaceRoute component={TasksCalender} {...props} />,
  //   location: "sideBar",
  //   layout: "mainLayout",
  //   isProtected: true,
  // },

  {
    path: `/notifications`,
    icon: LayoutDashboard,
    label: "notifications",
    component: (props) => (
      <WorkspaceRoute component={NotificationSettings} {...props} />
    ),
    location: null,
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: `/security`,
    icon: LayoutDashboard,
    label: "security",
    component: (props) => (
      <WorkspaceRoute component={SecuritySettings} {...props} />
    ),
    location: null,
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: `/teams`,
    icon: LayoutDashboard,
    label: "teams",
    component: (props) => (
      <WorkspaceRoute component={TeamSettings} {...props} />
    ),
    location: null,
    layout: "mainLayout",
    isProtected: true,
  },
  // {
  //   path: "${prefix}/language",
  //   icon: LayoutDashboard,
  //   label: "language",
  //   component: (props) => <WorkspaceRoute component={LanguageSettings} {...props} />,
  //   location: null,
  //   layout: "mainLayout",
  //   isProtected: true,
  // },
  // {
  //   path: "${prefix}/appearance",
  //   icon: LayoutDashboard,
  //   label: "appearance",
  //   component: (props) => <WorkspaceRoute component={AppearanceSettings} {...props} />,
  //   location: null,
  //   layout: "mainLayout",
  //   isProtected: true,
  // },
  // {
  //   path: "${prefix}/email-settings",
  //   icon: LayoutDashboard,
  //   label: "emailSettings",
  //   component: (props) => <WorkspaceRoute component={EmailSettings} {...props} />,
  //   location: null,
  //   layout: "mainLayout",
  //   isProtected: true,
  // },
  {
    path: `/billings`,
    icon: LayoutDashboard,
    label: "billings",
    component: (props) => (
      <WorkspaceRoute component={BillingSettings} {...props} />
    ),
    location: null,
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: `${prefix}/meeting-settings`,
    icon: Calendar,
    label: "meetingSettings",
    component: (props) => (
      <WorkspaceRoute component={MeetingSettings} {...props} />
    ),
    location: null,
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: "/integrations/google/callback",
    icon: LayoutDashboard,
    label: "billings",
    component: ConnectCallback,
    location: null,
    layout: "none",
    isProtected: true,
  },
];

export const allRoutes = [
  ...sidebarRoutes,
  ...protectedRRoutes,
  {
    path: "/auth/google/callback",
    icon: null,
    label: "GoogleCallback",
    component: GoogleCallback,
    location: null,
    layout: "none",
    isProtected: false,
  },
  {
    path: "/",
    icon: null,
    label: "HomePage",
    component: LandingPage,
    location: null,
    layout: "none",
    isProtected: false,
  },
  {
    path: "/login",
    icon: null,
    label: "HomePage",
    component: AuthPage,
    location: null,
    layout: "none",
    isProtected: false,
  },
  {
    path: "/register",
    icon: null,
    label: "HomePage",
    component: RegistrationWizard,
    location: null,
    layout: "none",
    isProtected: false,
  },
  {
    path: "/features",
    icon: null,
    label: "HomePage",
    component: FeaturesPage,
    location: null,
    layout: "none",
    isProtected: false,
  },
  {
    path: "/pricing",
    icon: null,
    label: "HomePage",
    component: PricingPage,
    location: null,
    layout: "none",
    isProtected: false,
  },
  {
    path: "/start-now",
    icon: null,
    label: "startTrial",
    component: OnboardingFlow,
    location: null,
    layout: "none",
    isProtected: false,
  },
  {
    path: "/verify-email",
    icon: null,
    label: "verify",
    component: VerifyEmailPage,
    location: null,
    layout: "none",
    isProtected: false,
  },
  {
    path: "/forget-password",
    icon: null,
    label: "verify",
    component: ForgotPasswordPage,
    location: null,
    layout: "auth",
    isProtected: false,
  },
  {
    path: "/reset-password",
    icon: null,
    label: "verify",
    component: ResetPasswordPage,
    location: null,
    layout: "auth",
    isProtected: false,
  },
  {
    path: "/unauthorized",
    icon: null,
    label: "unauthorized",
    component: Unauthorized,
    location: null,
    layout: "none",
    isProtected: false,
  },
  {
    path: "/help",
    icon: HelpCircle,
    label: "help",
    component: Help,
    location: null,
    layout: null,
    isProtected: false,
  },
  {
    path: "/onboarding/welcome",
    icon: null,
    label: "welcome",
    component: OnboardingWelcome,
    location: null,
    layout: "none",
    isProtected: true,
  },
];
