export const setAPIUrl = () => {
  // return  'https://api-task.knighthunt.com';

  const url = window.location.href;
  if (url.includes('localhost')) {
    return 'http://localhost:5465';
  } else if (url.includes('knighthunt')) {
    return 'https://api-task.knighthunt.com';
  }
}

export const getCurrentWorkspace = (workspaces) => {
  return workspaces.find(workspace => workspace.isCurrentlySelected);
}
