const LoadingSpinner = () => {
    return (
      <div className={`fixed w-full h-full z-[99] bg-primary-darkest opacity-50`}>
        <div className="flex items-center justify-center h-full ">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-300 relative z-50"/>
        </div>
      </div>
    )
  }
;

export default LoadingSpinner;
