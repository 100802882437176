import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Suspense } from "react";
import { allRoutes } from "@router/all.routers.jsx";
import { AuthProvider } from "./contexts/AuthContext";
import ProtectedRoute from "./router/ProtectedRoute";
import MainLayout from "./layouts/MainLayout";
import PublicLayout from "./layouts/PublicLayout";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { MeetingProvider } from "@/contexts/MeetingContext.jsx";
import { TaskProvider } from "@/contexts/TaskContext.jsx";
import {TooltipProvider} from "@/components/ui/tooltip.jsx";
import {IntegrationProvider} from "@/contexts/IntegrationContext.jsx";
import LoadingSpinner from "@/components/common/spinner.jsx";

const RouteWrapper = ({layout, component: Component, isProtected}) => {
  if (layout === "mainLayout" && isProtected) {
    return (
      <ProtectedRoute>
        <MainLayout>
          <Component />
        </MainLayout>
      </ProtectedRoute>
    );
  }

  if (layout === "auth") {
    return <Component />;
  }

  if (isProtected) {
    return (
      <ProtectedRoute>
        <Component />
      </ProtectedRoute>
    );
  }

  if (layout === "none") {
    return <Component />;
  }

  return (
    <PublicLayout>
      <Component />
    </PublicLayout>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingSpinner />}>
        <GoogleOAuthProvider clientId={`973115181403-g7vt4mqfpbpe89rk5ovsditf67dg4bju.apps.googleusercontent.com`}>
          <AuthProvider>
            <TooltipProvider>
                <IntegrationProvider>
                  <MeetingProvider>
                      <TaskProvider>
                    <Routes>
                      {allRoutes.map(({ layout, path, component, isProtected }) => (
                        <Route
                          key={path}
                          path={path}
                          element={
                            <RouteWrapper
                              layout={layout}
                              component={component}
                              isProtected={isProtected}
                            />
                          }
                        />
                      ))}
                    </Routes>
                      </TaskProvider>
                  </MeetingProvider>
                </IntegrationProvider>
            </TooltipProvider>
          </AuthProvider>
        </GoogleOAuthProvider>
      </Suspense>
    </BrowserRouter>
  )

};

export default App;
