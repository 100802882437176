import React from 'react';
import { Card, CardHeader, CardTitle, CardContent, CardDescription } from "@/components/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import { Flag, Clock, User2, Calendar } from 'lucide-react';

export const TaskPropertiesSection = ({ settings, handleSettingChange }) => (
  <Card className="border-none shadow-none">

    <CardContent className="space-y-8">
      <div className={`flex justify-between gap-6`}>
        {/* Priority Settings */}
        <div className="w-full space-y-4">
          <div className="flex items-center gap-2">
            <Flag className="h-5 w-5 text-primary" />
            <h3 className="text-lg font-medium">Task Priority</h3>
          </div>
          <div className="pl-0">
            <div className="grid gap-4 max-w-lg">
              <div className="p-4 rounded-lg space-y-3">
                <div className="text-sm font-medium">Default Priority Level</div>
                <div className="text-sm text-muted-foreground">
                  This priority will be applied when none is specified
                </div>
                <Select
                  value={settings.taskProperties.defaultPriority}
                  onValueChange={(value) =>
                    handleSettingChange('taskProperties', 'defaultPriority', value)
                  }
                >
                  <SelectTrigger className="w-48">
                    <SelectValue placeholder="Select priority"/>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="high">High Priority</SelectItem>
                    <SelectItem value="medium">Medium Priority</SelectItem>
                    <SelectItem value="low">Low Priority</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>
        </div>
        {/* Status Settings */}
        <div className="w-full space-y-4">
          <div className="flex items-center gap-2">
            <Clock className="h-5 w-5 text-primary" />
            <h3 className="text-lg font-medium">Task Status</h3>
          </div>
          <div className="pl-0">
            <div className="grid gap-4 max-w-lg">
              <div className=" p-4 rounded-lg space-y-3">
                <div className="text-sm font-medium">Initial Status</div>
                <div className="text-sm text-muted-foreground">
                  The starting point in your task workflow
                </div>
                <Select
                  value={settings.taskProperties.defaultStatus}
                  onValueChange={(value) =>
                    handleSettingChange('taskProperties', 'defaultStatus', value)
                  }
                >
                  <SelectTrigger className="w-48">
                    <SelectValue placeholder="Select status"/>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="todo">To Do</SelectItem>
                    <SelectItem value="inProgress">In Progress</SelectItem>
                    <SelectItem value="review">In Review</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Separator />
      <div className={`flex justify-between gap-6`}>
        {/* Assignment Settings */}
        <div className="w-full space-y-4">
          <div className="flex items-center gap-2">
            <User2 className="h-5 w-5 text-primary" />
            <h3 className="text-lg font-medium">Task Assignment</h3>
          </div>
          <div className="pl-0">
            <div className="grid gap-4 max-w-lg">
              <div className="p-4 rounded-lg space-y-3">
                <div className="text-sm font-medium">Default Assignee</div>
                <div className="text-sm text-muted-foreground">
                  Choose who tasks are assigned to by default
                </div>
                <Select
                  value={settings.taskProperties.defaultAssignee}
                  onValueChange={(value) =>
                    handleSettingChange('taskProperties', 'defaultAssignee', value)
                  }
                >
                  <SelectTrigger className="w-48">
                    <SelectValue placeholder="Select default assignee"/>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="creator">Task Creator</SelectItem>
                    <SelectItem value="unassigned">Leave Unassigned</SelectItem>
                    <SelectItem value="teamLead">Team Lead</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>
        </div>

        {/* Due Date Settings */}
        <div className="w-full space-y-4">
          <div className="flex items-center gap-2">
            <Calendar className="h-5 w-5 text-primary" />
            <h3 className="text-lg font-medium">Due Date</h3>
          </div>
          <div className="pl-0">
            <div className="grid gap-4 max-w-lg">
              <div className="p-4 rounded-lg space-y-3">
                <div className="text-sm font-medium">Due Date Behavior</div>
                <div className="text-sm text-muted-foreground">
                  Set default due date behavior for new tasks
                </div>
                <Select
                  value={settings.taskProperties.dueDateBehavior}
                  onValueChange={(value) =>
                    handleSettingChange('taskProperties', 'dueDateBehavior', value)
                  }
                >
                  <SelectTrigger className="w-48">
                    <SelectValue placeholder="Select due date behavior"/>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="none">No Default Due Date</SelectItem>
                    <SelectItem value="nextDay">Next Day</SelectItem>
                    <SelectItem value="nextWeek">Next Week</SelectItem>
                    <SelectItem value="custom">Custom Time Frame</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CardContent>
  </Card>
);
