import React from 'react';
import { Card, CardHeader, CardTitle, CardContent, CardDescription } from "@/components/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import { Separator } from "@/components/ui/separator";
import {
  Gauge,
  MessageSquare,
  Mail,
  FileText,
  Video,
  Keyboard,
  Brain
} from 'lucide-react';

export const DetectionSettingsSection = ({ settings, handleSettingChange }) => (
  <Card className="border-none shadow-none">
    <CardContent className="space-y-8">
      {/* AI Sensitivity Group */}
      <div className="space-y-4">
        <div className="flex items-center gap-2">
          <Gauge className="h-5 w-5 text-primary" />
          <h3 className="text-lg font-medium">Detection Sensitivity</h3>
        </div>
        <div className="pl-7">
          <div className="grid gap-4">
            <div>
              <div className="text-sm font-medium mb-1.5">AI Detection Level</div>
              <div className="text-sm text-muted-foreground mb-3">
                Adjust how aggressively tasks are identified
              </div>
              <Select
                value={settings.detection.sensitivity}
                onValueChange={(value) => handleSettingChange('detection', 'sensitivity', value)}
              >
                <SelectTrigger className="w-48">
                  <SelectValue placeholder="Select sensitivity"/>
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="high">High Sensitivity</SelectItem>
                  <SelectItem value="medium">Medium Sensitivity</SelectItem>
                  <SelectItem value="low">Low Sensitivity</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>
      </div>

      <Separator />

      {/* Source Types Group */}
      <div className="space-y-4">
        <div className="flex items-center gap-2">
          <MessageSquare className="h-5 w-5 text-primary" />
          <h3 className="text-lg font-medium">Detection Sources</h3>
        </div>
        <div className="pl-7">
          <div className="grid gap-6">
            <div>
              <div className="text-sm font-medium mb-1.5">Monitored Sources</div>
              <div className="text-sm text-muted-foreground mb-4">
                Select which content types to monitor for tasks
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div className="flex items-center gap-3 bg-muted/50 p-3 rounded-lg">
                  <Mail className="h-4 w-4 text-muted-foreground" />
                  <div className="flex-1">
                    <div className="text-sm font-medium">Email</div>
                  </div>
                  <Switch
                    checked={settings.detection.sources.email}
                    onCheckedChange={(checked) =>
                      handleSettingChange('detection', 'sources.email', checked)
                    }
                  />
                </div>
                <div className="flex items-center gap-3 bg-muted/50 p-3 rounded-lg">
                  <MessageSquare className="h-4 w-4 text-muted-foreground" />
                  <div className="flex-1">
                    <div className="text-sm font-medium">Chat</div>
                  </div>
                  <Switch
                    checked={settings.detection.sources.chat}
                    onCheckedChange={(checked) =>
                      handleSettingChange('detection', 'sources.chat', checked)
                    }
                  />
                </div>
                <div className="flex items-center gap-3 bg-muted/50 p-3 rounded-lg">
                  <FileText className="h-4 w-4 text-muted-foreground" />
                  <div className="flex-1">
                    <div className="text-sm font-medium">Documents</div>
                  </div>
                  <Switch
                    checked={settings.detection.sources.documents}
                    onCheckedChange={(checked) =>
                      handleSettingChange('detection', 'sources.documents', checked)
                    }
                  />
                </div>
                <div className="flex items-center gap-3 bg-muted/50 p-3 rounded-lg">
                  <Video className="h-4 w-4 text-muted-foreground" />
                  <div className="flex-1">
                    <div className="text-sm font-medium">Meetings</div>
                  </div>
                  <Switch
                    checked={settings.detection.sources.meetings}
                    onCheckedChange={(checked) =>
                      handleSettingChange('detection', 'sources.meetings', checked)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Separator />

      {/* Detection Methods Group */}
      <div className="space-y-4">
        <div className="flex items-center gap-2">
          <Brain className="h-5 w-5 text-primary" />
          <h3 className="text-lg font-medium">Detection Methods</h3>
        </div>
        <div className="pl-7">
          <div className="grid gap-6">
            <div className="grid grid-cols-2 gap-4">
              <div className="flex flex-col gap-4 bg-muted/50 p-4 rounded-lg">
                <div className="flex items-center gap-3">
                  <Keyboard className="h-4 w-4 text-muted-foreground" />
                  <div className="flex-1">
                    <div className="text-sm font-medium">Keyword Detection</div>
                    <div className="text-sm text-muted-foreground mt-0.5">
                      Detect tasks using trigger words
                    </div>
                  </div>
                  <Switch
                    checked={settings.detection.keywordEnabled}
                    onCheckedChange={(checked) =>
                      handleSettingChange('detection', 'keywordEnabled', checked)
                    }
                  />
                </div>
              </div>
              <div className="flex flex-col gap-4 bg-muted/50 p-4 rounded-lg">
                <div className="flex items-center gap-3">
                  <Brain className="h-4 w-4 text-muted-foreground" />
                  <div className="flex-1">
                    <div className="text-sm font-medium">NLP Processing</div>
                    <div className="text-sm text-muted-foreground mt-0.5">
                      AI-powered context detection
                    </div>
                  </div>
                  <Switch
                    checked={settings.detection.nlpEnabled}
                    onCheckedChange={(checked) =>
                      handleSettingChange('detection', 'nlpEnabled', checked)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CardContent>
  </Card>
);
